import { 

  OFFER_TEAM_LEADER_GET,
  OFFER_TEAM_LEADER_SET

      } from "./actionTypes"






export const Offer_TeamLeader_GET = (id) => ({
  type:OFFER_TEAM_LEADER_GET,
  id
})
export const Offer_TeamLeader_SET = teamleader => ({
  type:OFFER_TEAM_LEADER_SET,
  teamleader,
})

