import { 
  
  JOBS_FRIEND_SET ,
  JOBS_FRIEND_CHECK_SET
        } from "./actionTypes"


const initialState = {
    error:"",
    status: false,
}


export const JobsFriendReducers = (state = initialState,action) => {

    switch (action.type) {
    
          case JOBS_FRIEND_SET:
            return {
              ...state,
              action
            };
          
        default:
          return { ...state };
    }


}


export  const JobsFriendCheckdReducers = (state = initialState,action) => {

  switch (action.type) {
  
       
          case JOBS_FRIEND_CHECK_SET:
            return {
              ...state,
              action
            };
      default:
        return { ...state };
  }

  

}


        