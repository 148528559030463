import React from "react"
import { Navigate } from "react-router-dom"


// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import RegisterVerification from "../pages/Authentication/RegisterVerification"

// Dashboard
import Dashboard from "../pages/Dashboard/index"


//Profil Pages
import Profile from "../pages/Profil/index" 



//Offers Pages
import CreateOffer from "pages/Offer/createOffer"
import OfferList from "pages/Offer/offerList"
import EditOffer from "pages/Offer/editOffer"

//Chat Pages
import Chat from "pages/Chat"

// My Team
import MyTeam from "pages/Team/index"

//OfferView
import OfferView from "pages/Offer/offerView"
import PartnerDetail from "pages/Offer/PartnerDetail"

//Message Pages
import Messages from "pages/Message"

//Notification
import Notification from "pages/Notification"

const authProtectedRoutes = [
    
    //Dashboard
    { path: "/Dashboard", component: <Dashboard/> },

    //Offers
    { path: "/CreateOffer", component: <CreateOffer />  },
    { path: "/MyOffers", component: <OfferList />  },
    { path: "/EditOffer/:id", component: <EditOffer />  },
    { path: "/OfferView/:id", component: <OfferView />  },
    { path: "/PartnerDetail/:id", component: <PartnerDetail />  },

    //Messages
    { path: "/Messages", component: <Messages />  },


    //Notification
    { path: "/Notification", component: <Notification />  },

    //Chat
    { path: "/Chat", component: <Chat />  },

    //MyTeam
    { path: "/MyTeam", component: <MyTeam />  },


    //Profile
    { path: "/Profile", component: <Profile />  },


  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
   {
    path: "/",
    exact: true,
    component: < Navigate to="/Dashboard" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/RegisterVerification", component: <RegisterVerification />   }
]

export { authProtectedRoutes, publicRoutes }

