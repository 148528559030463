
import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';





const  AboutUs  = ({info}) => { 




    return (
        <React.Fragment>
            <Col lg={12}>
                <Card>
                    <CardBody>
                        
                        <h5 className="mb-3">About Us</h5>
                        <p  className="text-muted"> {  info ? 
                        
                        <div dangerouslySetInnerHTML={{ __html:info.aboutus.about }}></div>
                        : null }</p>

                    </CardBody>
                </Card>




            </Col>
        </React.Fragment>
    );



}

export default AboutUs;

/*
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody className="border-bottom">
                                    <h5 className="mb-3">Social Media</h5>
                                    <div className="hstack gap-2">
                                        <Link to="#!" className="btn btn-soft-primary"><i className="bx bxl-facebook align-middle me-1"></i> Facebook </Link>
                                        <Link to="#!" className="btn btn-soft-info"><i className="bx bxl-twitter align-middle me-1"></i> Twitter</Link>
                                        <Link to="#!" className="btn btn-soft-pink"><i className="bx bxl-instagram align-middle me-1"></i> Instagram</Link>
                                        <Link to="#!" className="btn btn-soft-success"><i className="bx bxl-whatsapp align-middle me-1"></i> Whatsapp</Link>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

*/