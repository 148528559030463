

import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import { format, parse, compareAsc } from 'date-fns';


const MyComponent = ({teams}) => {

    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [sortedData, setSortedData] = useState([]);
    const months = Array.from({ length: 12 }, (_, i) => i + 1);
  
    const getDayName = (dateStr) => {
      const date = parse(dateStr, 'dd-MM-yyyy', new Date());
      return format(date, 'EEEE');
    };

    useEffect(() => {
        let sorted = [];
        const daysInMonth = new Date(2024, selectedMonth, 0).getDate();
        for (let i = 1; i <= daysInMonth; i++) {
          const day = i < 10 ? `0${i}` : `${i}`;
          const month = selectedMonth < 10 ? `0${selectedMonth}` : `${selectedMonth}`;
          const dateStr = `${day}-${month}-2024`;

          const worker = teams.find(worker => worker.date.includes(dateStr));

          sorted.push({
            dateStr,
            dayName: getDayName(dateStr),
            className: worker ? worker.className : "badge-soft-danger me-1 rounded-b-sm badge bg-secondary",
            name: worker ? worker.name : "Not planned",
          });
        }
        setSortedData(sorted);
    }, [selectedMonth,teams]);

    return (
      <Row>
        <select className='form-select mb-8' onChange={(e) => setSelectedMonth(Number(e.target.value))}>
          {months.map((month) => (
            <option key={month} value={month}>
              {format(new Date(2024, month - 1), 'MMMM')}
            </option>
          ))}
        </select>

        {sortedData.map((item, i) => (
          <Card className="flex-column p-0 rounded-b-lg shadow-lg mr-4 mb-3 w-[125px]" key={i}>
            <CardBody className="border-bottom rounded-t-md p-1 mb-1 bg-gray-700 text-cyan-50">
              <div className="font-bold">{item.dayName}</div>
            </CardBody>

            <div className="p-2 text-center">{item.dateStr}</div>
            <div className={`${item.className} mt-1 p-1 h-full w-full`}>{item.name}</div>
          </Card>
        ))}
      </Row>
    );
};

export default MyComponent;