import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER, RESENT_CODE,VERIFY_CODE, PASSWORD_FORGOT} from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"



import { notify } from "store/notification/action";
import {API_SERVICE} from "../../../ApiConfig"


import axios from "axios";


// Is user register successfull then direct plot user in redux.
function* registerPartner({ payload: { user, history } }) {
  try {

    async function API(e) {


    

      const response = await axios
        .post(`${API_SERVICE}RegisterPartner`, {
          RegisterToken: "TheWawy_Register_Token",
          email: e.email,
          password: e.password,
          phone: e.phone,
          fullname: e.fullname,
          referencecode: localStorage.getItem('ReferenceCode') ? localStorage.getItem('ReferenceCode') : 'default_system_registered'
        })
        .then(response => response.data)
        .catch((err) => {
          console.log("Cacth Service Down")
          const data = { status: "Network Error", message_no: 2, notify: 'Service Down' }
          return data
        })
    //  console.log(response)

      return response

    }


    const response = yield call(API, user)

    if (response.message_no == 0) {
      yield put(registerUserSuccessful(response))
    }
    else {
      yield put(registerUserSuccessful(response))
      localStorage.setItem("phone", "+" + user.phone)
      history("/RegisterVerification")
    }


  }

  catch (error) {
    console.log("There was an error registering: ", error)
    yield put(registerUserFailed(error))
  }
}


function* RegisterPartnerVerifyCode ({payload : {code, phone, history}}) {


  async function API (code,phone,history) {

    const response = await  axios
      .post(`${API_SERVICE}VerifyCode`, {
          RegisterToken:"TheWawy_Register_Token",
          verifyCode:String(code),
          verifyPhone:phone
      })
      .then(response => response.data)
      .catch((err) => {
        console.log("Cacth Service Down")
        const data = {status: "Network Error", message_no: 2, notify: 'Service Down'}
        return data
      })
    
     if(response.message=="pending" ) {} else if ( response.message=="successful" ) {localStorage.removeItem('phone')} else {localStorage.removeItem('phone')}

    return response
    
    }

  const response = yield call(API,code,phone,history)


  
   if(response.message=='approved') {
   history("/login") 
  } else {
    
  }
  
}



function* RegisterPartnerResendCode ({payload : { phone, history}}) {

  async function API (phone,history) {
    const response = await  axios
    .post(`${API_SERVICE}ResendCode`, {
        RegisterToken:"TheWawy_Register_Token",
        verifyPhone:phone
    })
    .then(response => response.data)
    .catch((err) => {
      console.log("Cacth Service Down")
      const data = {status: "Network Error", message_no: 2, notify: 'Service Down'}
      return data
    })
  
 
  return response
  }

 const response = yield call(API,phone,history)

}



// password forgot
function* PasswordForgot({ email }) {
  try {

    async function API() {


    

      const response = await axios
        .post(`${API_SERVICE}PasswordForget`, {
          RegisterToken: "TheWawy_Register_Token",
          email: email,
        })
        .then(response => response.data)
        .catch((err) => {
          console.log("Cacth Service Down")
          const data = { status: "Network Error", message_no: 2, notify: 'Service Down' }
          return data
        })
    //  console.log(response)

      return response

    }


      // Login İşleminde Sunucudan Dönen Yanıt
      const datam = yield call(API)
      yield put(notify(datam)) 


  }

  catch (error) {
    console.log("There was an error registering: ", error)
    yield put(registerUserFailed(error))
  }
}



export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerPartner)
  yield takeEvery(VERIFY_CODE, RegisterPartnerVerifyCode)
  yield takeEvery(RESENT_CODE, RegisterPartnerResendCode)
  yield takeEvery(PASSWORD_FORGOT, PasswordForgot)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
