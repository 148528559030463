import React, { Component } from 'react'
import { Row, Col, Card, CardBody ,CardTitle,Table} from "reactstrap";

const Services = ({info}) =>{
 
    return (
    <Card>
        <CardBody>
      
     
          <Row>
    
    
      {Object.values(info.services).map((item, index) => {
        if (item.status) {
       
          return (
            <Col lg="12 mt-4" key={index}>

              <Row>
                <Col lg="12 d-grid gap-2">
                {
                      item.name == "UK Unregistered Nurse"
                      ? <h4 className='btn-soft-success waves-effect waves-light btn' style={{ paddingLeft: "5px" }}> <span>{item.name}</span></h4>
                      : item.name == "Healthcare"
                        ? <h4 className='btn-soft-primary waves-effect waves-light btn' style={{ paddingLeft: "5px" }}> <span>{item.name}</span></h4>
                        : item.name == "Housekeeping"
                          ? <h4 className='btn-soft-info waves-effect waves-light btn' style={{ paddingLeft: "5px" }}> <span>{item.name}</span></h4>
                          : item.name == "Nannies"
                            ? <h4 className='btn-soft-warning waves-effect waves-light btn' style={{ paddingLeft: "5px" }}> <span>{item.name}</span></h4>
                            : item.name == "Babysitter"
                              ? <h4 className='btn-soft-danger waves-effect waves-light btn' style={{ paddingLeft: "5px" }}> <span>{item.name}</span></h4>
                              : <h4 className='btn-soft-secondary waves-effect waves-light btn' style={{ paddingLeft: "5px" }}> <span>{item.name}</span></h4>
                    }
                </Col>
               
                <div className="text-muted mt-4"></div>
                {item.service.map((service, i) => {
                  if (service.checked) {
                    return (
                      <Col lg="6" key={i}>
                        <p>
                          <i className="mdi mdi-chevron-right text-primary me-1" />{" "}
                          {service.name}
                        </p>
                      </Col>
                    );
                  }
                  return null;
                })}
              </Row>
            </Col>
          );
        }
        return null;
      })}
  

          </Row>






        </CardBody>
      </Card>
    )

}


export default Services
