import { takeEvery, put, call } from "redux-saga/effects"
import axios from "axios";
import { API_SERVICE_QUERY, API_SERVICE_UPDATE } from "ApiConfig";
import { jwtDecode as jwtDecode } from 'jwt-decode';
import { SCHEDULE_SET } from "./actionTypes"
import { Schedule_GET } from "./actions";
import { notify } from "store/notification/action";


function* Schedule ({schedule_id, schedule}) {


 
   async function API() {
       
     
 
       const response = await axios
          .post(`${API_SERVICE_UPDATE}Schedule`, {
             schedule_id: schedule_id,
             schedule: schedule,
             token: JSON.parse(localStorage.getItem("authUser"))
          })
          .then(response => response)
          .catch(err => {
             console.log("Bitmedi")
             // LoginButtonStatus(dispatch,"a")
          })
 
 
       return response.data
    }
 
    // Login İşleminde Sunucudan Dönen Yanıt
    const datam = yield call(API)

    yield put(notify(datam)) 
 //   yield put(Schedule_GET(datam)) 

 
}




function* Schedule_Saga() {


    yield takeEvery(SCHEDULE_SET, Schedule)

 }
 
 export default Schedule_Saga