import React from 'react'
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { useSelector, useDispatch } from "react-redux";
import { useState } from 'react';
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Button,FormGroup } from "reactstrap";
import { Link } from "react-router-dom";
import { useEffect } from 'react';
import AuthCode from "react-auth-code-input";
// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import {  verifyCode, resendCode  } from "../../store/actions";




const  RegisterVerification = props => {

const dispatch = useDispatch();

const [code, setCode ] = useState([])
const [seconds, setSeconds] = useState("")
const [IsInvalid, setIsInvalid] = useState(true)



useEffect(() =>{
    timerStart()
}, [])

const timerStart = ()=>{
    
    setSeconds(60)
    
    let mySeconds = 60;

    // TODO Clear previos interval

    const intervalId = setInterval(() => {
      mySeconds = mySeconds - 1;
      
      setSeconds(mySeconds)

      if (mySeconds === 0) {
        clearInterval(intervalId);
        setSeconds(0)
        setIsInvalid(true)
        
      }
    }, 1000);

    setTimeout(() => {
      setIsInvalid(false)
    }, 1000);

    
  }



  return (
    <React.Fragment>
       
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mb-5 text-muted">
                  <Link to="dashboard" className="d-block auth-logo">
                    <img
                      src={logodark}
                      alt=""
                      height="20"
                      className="auth-logo-dark mx-auto"
                    />
                    <img
                      src={logolight}
                      alt=""
                      height="20"
                      className="auth-logo-light mx-auto"
                    />
                  </Link>
                  <p className="mt-3">React Admin & Dashboard Template</p>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card>
                  <CardBody>
                    <div className="p-2">
                      <div className="text-center">
                        <div className="avatar-md mx-auto">
                          <div className="avatar-title rounded-circle bg-light">
                            <i className="dripicons-time-reverse h3 mt-4 text-primary font-weight-semibold" style={{fontStyle:"normal"}} ><p>{IsInvalid == false ? `${seconds}` :""} </p></i>
                          </div>
                        </div>
                        <div className="p-2 mt-4">
                          <h4>Verify your phone</h4>
                          <p className="mb-5">
                            Please enter the 4 digit code sent to{" "}
                            <span className="font-weight-semibold">
                              {localStorage.getItem("phone")}
                            </span>
                          
                          </p>
                        
                           
                          
                          <Form>
                            <Row>
                              <Col xs={12}>
                                <FormGroup className="verification2">
                                  <label
                                    htmlFor="digit1-input"
                                    className="sr-only"
                                  >
                                    Dight 1
                                  </label>

                            
                                  <AuthCode
                                    
                                    length={4}
                                    onChange={(e) => setCode(e)}
                                    
                                    inputClassName="verificaiton"
                                    allowedCharacters="numeric"
                                    autoFocus={false}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>

                          
                          <div className="mt-4">
                          {IsInvalid == false ?  
                            <Button
                              type="submit"
                              className="btn btn-success w-md"
                              onClick={
                                () => dispatch(verifyCode({code,phone:localStorage.getItem('phone')}, props.router.navigate))
                              
                                
                               }
                            >
                              Confirm
                            </Button> : "" }

                  {IsInvalid == true ?                   
                 <p>
                    <span className="font-weight-semibold">Did&apos;t receive a code ?</span> <br></br>
                    <Button className="btn btn-success w-md" onClick={() => {   dispatch(resendCode(localStorage.getItem('phone'), props.router.navigate), timerStart())}}>
                    Resend
                    </Button>{" "}
                  </p> :"" }  

                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">

               

                  <p>
                    © {new Date().getFullYear()} Thw Wawy. Crafted with{" "}
                    <i className="mdi mdi-heart text-danger"></i> by  OCTILLIONSOFT LIMITED
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  )
}




export default withRouter(RegisterVerification);

RegisterVerification.propTypes = {
    history: PropTypes.object,
  };


  