import React, { useEffect, useState } from "react";
// import { Link, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { map } from "lodash";

import {
    Badge,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Button,
    Tooltip,
    Modal,

} from "reactstrap";


//Import Component
import Breadcrumbs from "components/Common/Breadcrumb";

import { Offer_GET, Offer_Delete } from "store/offer/actions";

//Import Image
import images from "assets/images";
import companies from "assets/images/companies";
import { Dna } from 'react-loader-spinner'
import Swal from 'sweetalert2'
import AvatarModule from "./component/avatarModule";


const ProjectStatus = ({ status }) => {
    switch (status) {

        case 1:
            return <Badge className="bg-success"> {"Completed"} </Badge>;

        case 2:
            return <Badge className="bg-danger"> {"Removed"} </Badge>;

        case 3:
            return <Badge className="bg-warning"> {"Stopped"} </Badge>;


        default:
            return <Badge className="bg-info"> {"Publish"} </Badge>;

    }
};


const JobType = ({ status }) => {
    switch (status) {

        case "Nurse":
            return <Badge className="bg-secondary"> {"Nurse"} </Badge>;

        case "UNR Nurse":
            return <Badge className="bg-secondary"> {"UNR Nurse"} </Badge>;

        case "Healthcare":
            return <Badge className="bg-secondary"> {"Healthcare"} </Badge>;

        case "Nannies":
            return <Badge className="bg-secondary"> {"Nannies"} </Badge>;

        case "Babysitter":
            return <Badge className="bg-secondary"> {"Babysitter"} </Badge>;

        case "Housekeeping":
            return <Badge className="bg-secondary"> {"Housekeeping"} </Badge>;


    }
};




//redux
import { useDispatch, useSelector } from 'react-redux';

const ProjectsList = () => {


    //meta title
    document.title = "Offer List | The Wawy - Dashboard ";

  
    const [controlDispatch, SetControlDispatch] = useState(true);
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(Offer_GET())

    }, [dispatch, controlDispatch])







    const offers = useSelector(state => state.OfferReducers.action);
    const notify = useSelector(state => state.NotifyReducer.action);
    var MyOffers = []

    if (offers && offers.OfferData) { var MyOffers = offers.OfferData.offers }

    const handleApply = (id, notify) => {



        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, remove it!'

        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Removed!',
                    'Your request has been applied.',
                    'success'
                )
                dispatch(Offer_Delete(id));

                if (controlDispatch) { SetControlDispatch(false) } else { SetControlDispatch(true) }


            } else {// setApply(false)
            }
        })




    }




    return (
        <React.Fragment>

            <div className="page-content" style={{ backgroundColor: "#f2f2f5" }}>
                <Container fluid>





                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Offers" breadcrumbItem="My Offer List" />

                    <Row>
                        <Col lg="12">
                            <div >
                                <div className="table-responsive">
                                    {MyOffers ?
                                        <Table className="project-list-table table-nowrap align-middle table-borderless">

                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ width: "100px" }}>
                                                        Priority
                                                    </th>
                                                    <th scope="col">Offer Title</th>
                                                    <th scope="col">Publish Date</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Apply</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {map(MyOffers, (offer, index) => (
                                                    <tr key={index}>

                                                        <td>
                                                            {offer.priority.map((item, key) => {
                                                                if (item.checked === true) {
                                                                    return (
                                                                        <img
                                                                            key={key}
                                                                            src={item.images}
                                                                            alt=""
                                                                            className="w-[68px]"
                                                                        />
                                                                    );
                                                                }
                                                                return null;
                                                            })}

                                                        </td>


                                                        <td>
                                                            <h5 className="text-truncate font-size-14">
                                                                <Link
                                                                    to={`/projects-overview/${offer.id}`}
                                                                    className="text-dark"
                                                                >
                                                                    {offer.job_title}
                                                                </Link>
                                                            </h5>
                                                            <p className="text-muted mb-0  truncate">
                                                                <JobType status={offer.job_type} />
                                                            </p>
                                                        </td>

                                                        <td> {offer.create_date}</td>
                                                        <td>
                                                            <ProjectStatus status={offer.job_status} />
                                                            {/* <Badge className={"bg-" + project.color}>
                              {project.status}
                            </Badge> */}
                                                        </td>

                                                        <td>
                                                            <div className="avatar-group"><AvatarModule avatar={offer} /> </div>
                                                        </td>

                                                        <td>

                                                            <Link to={`/OfferView/${offer._id}`} >   <Button id="TooltipTopP" color="warning mr-1 p-1" outline>
                                                                <i className="mdi mdi-eye  pl-1 pr-1 mt-1 text-[14px]"></i>
                                                            </Button></Link>
                                                            <Link to={`/EditOffer/${offer._id}`} >    <Button id="TooltipTopE" color="info  mr-1 p-1" outline>
                                                                <i className="bx bx-edit pl-1 pr-1 mt-1 text-[14px]"></i>
                                                            </Button></Link>
                                                            <Button onClick={() => handleApply(offer._id)} id="TooltipTopR" color="danger p-1" outline>
                                                                <i className="bx bxs-trash  pl-1 pr-1 mt-1 text-[14px]"></i>
                                                            </Button>
                                                        </td>

                                                    </tr>
                                                ))}
                                            </tbody>

                                        </Table>
                                        :
                                        <Dna
                                            visible={true}
                                            height="160"
                                            width="160"
                                            ariaLabel="dna-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="dna-wrapper"
                                        />

                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    );
};

export default ProjectsList;



/*

               <td>
                                                    <Tooltip placement="top"isOpen={tP} target="TooltipTopP"  toggle={() => {setTP(!tP); }}> Preview Offer </Tooltip>
                                                    <Tooltip placement="top"isOpen={tE} target="TooltipTopE" toggle={() => {setTE(!tE); }}> Edit Offer </Tooltip>
                                                    <Tooltip placement="top"isOpen={tR} target="TooltipTopR" toggle={() => {setTR(!tR); }}> Remove Offer </Tooltip> 
                                                        <Button id="TooltipTopP"  color="warning mr-1 p-1"  outline>
                                                        <i className="mdi mdi-eye  pl-1 pr-1 mt-1 text-[14px]"></i>
                                                        </Button>
                                                        <Link to={`/EditOffer/${offer._id}`} >    <Button id="TooltipTopE"  color="info  mr-1 p-1" outline>
                                                          <i className="bx bx-edit pl-1 pr-1 mt-1 text-[14px]"></i>
                                                        </Button></Link>
                                                        <Button onClick={() => handleApply(offer._id)} id="TooltipTopR"  color="danger p-1" outline>
                                                        <i className="bx bxs-trash  pl-1 pr-1 mt-1 text-[14px]"></i>
                                                        </Button>
                                                    </td>

*/