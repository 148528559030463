import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { useSelector } from "react-redux";

import { PROFIL_IMG } from "ApiConfig";
const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("Admin");

  const dispatch = useDispatch();

  

  useEffect(() =>{
    dispatch({ type: 'API_DASHBOARD_GET' })
  },[props.data])

  const { data } = useSelector((state) => ({
    data: state.Dashboard.action.datam?.ServerDashboard,
  }));

  var profilImg = "";
  var name=""
  if(data) {var profilImg = data.profilimage.picture_path; var name=data.fullName} 


  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={`${PROFIL_IMG}${profilImg}`}
           
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{name}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end w-[40px]">
          <DropdownItem >
        
            
            <Link to="/Profile" className="dropdown-item pt-[1px] pb-[1px] -ml-[24px] ">
            <i className="bx bx-user font-size-16 align-middle me-1" />{" "}
          {props.t("Profile")}{" "}
          </Link>
          </DropdownItem>

        
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
