import {API_DASHBOARD_SET } from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  action: {},
}

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {

    case API_DASHBOARD_SET:
      state = {
        ...state,
        action,
      }

    default:
      state = { ...state }
      break
  }
  return state
}

export default DashboardReducer
