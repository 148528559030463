import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';

const Chat = (props) => {
  document.title = 'Starter Page | The Wawy - Client Dashboard ';

  const socket = io('https://partner.thewawy.co.uk:3036');
  const [room, setRoom] = useState('JOBS5150');
  const [message, setMsg] = useState('Merhaba, bu bir mesajdır.');

  const sendMessage = () => {
    socket.emit('SendMessage', {  room, message });
  };

  useEffect(() => {

  socket.emit('JoinRoom',room);
   
  },[])




  useEffect(() => {
    socket.on('connect', () => {
      console.log('Bağlantı sağlandı');
    });

    socket.on('disconnect', () => {
      console.log('Bağlantı kesildi');
    });

    socket.on('connect_error', (error) => {
      console.log('Bağlantı hatası:', error);
    });

    socket.on('connect_timeout', () => {
      console.log('Bağlantı zaman aşımına uğradı');
    });

 

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('connect_error');
      socket.off('connect_timeout');
      socket.off('ReceiveMessage');
    };
  }, []);



  useEffect(() => {

    socket.on('ReceiveMessage', (data) => {
      console.log('Yeni mesaj:', data);
     
    });

  },[])





  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Utility" breadcrumbItem="Starter Page" />
          <button onClick={sendMessage}>Test</button>
         
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state,
  };
};

export default connect(mapStateToProps)(withTranslation()(Chat));
