import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';  
import { useSelector } from 'react-redux';
import { PROFIL_IMG } from "ApiConfig";

import Lottie from 'lottie-react';
import worker from "../../../assets/lottie/worker4"; // Lottie JSON dosyasının yolu

const List = () => { 
    const activeBtn = (ele) => {
        if(ele.closest("button").classList.contains("active")) {
            ele.closest("button").classList.remove("active");
        } else {
            ele.closest("button").classList.add("active");
        }
    }

    const users = useSelector(state => state.Dashboard.action?.datam?.users)
   
    return (
        <React.Fragment>
            <Row>
                { users.length > 0 ?
                (users || []).map((item , key) => (
                <Col xl={4} key={key}>
                    <Card>
                        <CardBody>
                            <div className="d-flex align-start mb-3">
                                <div className="flex-grow-1">
                                    <span className={item.gender === "Man" ? "badge badge-soft-info" : item.gender === "Woman" ? "badge badge-soft-danger" : item.gender === "other" ? "badge badge-soft-success" : "badge badge-soft-warning"}>
                                        {item.gender}
                                    </span>
                                </div>
                                <button type="button" className="btn btn-light btn-sm like-btn" onClick={(e) => activeBtn(e.target)}>
                                    <i className="bx bx-heart"></i>
                                </button>
                            </div>
                            <div className="text-center mb-3">
                                <img src={`${PROFIL_IMG}${item.profilimage.picture_path}`} alt="" className="avatar-sm rounded-circle" />
                                <h6 className="font-size-15 mt-3 mb-1">{item.fullName}</h6>
                                <p className="mb-0 text-muted">{item.city}</p>
                            </div>
                            <div className="d-flex mb-3 justify-content-center gap-2 text-muted">
                                <div>
                                    <i className='bx bx-mail-send align-middle text-primary'></i> {item.email}
                                </div>
                                <p className="mb-0 text-center"><i className='bx bx-phone align-middle text-primary'></i>  {item.phone} </p>
                            </div>
      
                            <div className="flex  flex-wrap  space-x-1 justify-center items-center">

                            {
                              Object.values(item.services).filter(subItem => subItem.status === true).map((subItem , key) => (
                                        
                              
                                <span key={key} className={subItem.name === "Healthcare" ? "badge badge-soft-success mr-1 mb-1 " : subItem.name === "Nannies" ? "badge badge-soft-info mr-1  mb-1" : subItem.name === "Nurse Service" ? "badge badge-soft-danger mr-1  mb-1" : "badge badge-soft-warning mr-1  mb-1"}>
                                                         {subItem.name}
                                                    </span>
                                                        )) }
                                </div>
                               
                            <div className="mt-4 pt-1">
                                <Link to={`/PartnerDetail/${item._id}`} className="btn btn-soft-primary d-block">View Profile</Link>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                )) :
             
                    <Row className="flex flex-col items-center justify-start">
                        <Col className="flex flex-col items-center justify-start " >
                        <div className="alert alert-warning fade show" role="alert">The wawy is working to find the best person for you. We haven't found anyone yet.</div>
                            </Col>
                        <Col xl={6} sm={12}>
                            <Lottie
                                animationData={worker}
                                loop
                                autoplay={true}
                                renderer="svg" // İsteğe bağlı olarak svg veya canvas olarak belirleyebilirsiniz
                                style={{ width: "100%", height: "auto", marginTop:"-100px" }}
                               
                            />
                        </Col>
                    </Row>
             
                }
            </Row>
        </React.Fragment>
    );
}

export default List;