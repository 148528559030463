import { 

  JOBS_FRIEND_GET,
  JOBS_FRIEND_SET,
  JOBS_FRIEND_CHECK_GET,
  JOBS_FRIEND_CHECK_SET, 
      } from "./actionTypes"




export const Jobs_friend_GET = (job,friend_id) => ({
  type:JOBS_FRIEND_GET,
  FriendData : {job,friend_id},
})

export const Jobs_friend_SET = FriendData => ({
  type:JOBS_FRIEND_SET,
  FriendData,
})



export const Jobs_friend_check_GET = (job_id) => ({
  type:JOBS_FRIEND_CHECK_GET,
  job_id
})

export const Jobs_friend_check_SET = FriendCheckData => ({
  type:JOBS_FRIEND_CHECK_SET,
  FriendCheckData,
})