import { takeEvery, put, call } from "redux-saga/effects"
import axios from "axios";
import { API_SERVICE_QUERY } from "ApiConfig";
import {jwtDecode} from "jwt-decode"
import {
   PARTNER_PROFIL_GET,
   PASSWORD_GET
} from "./actionTypes";

import {
   Partner_Profil_SET,
 
   Password_SET
} from "./actions"




function* PartnerProfil({partner_id}) {



   async function API() {
   

      const response = await axios
         .post(`${API_SERVICE_QUERY}PartnerProfil`, {
            partner_id: partner_id,
            token: JSON.parse(localStorage.getItem("authUser")),
         })
         .then(response => response)
         .catch(err => {
            console.log("Bitmedi")
            // LoginButtonStatus(dispatch,"a")
         })

    
      return response.data
   }

   // Login İşleminde Sunucudan Dönen Yanıt
   const datam = yield call(API)

   yield put(Partner_Profil_SET(datam))

  
}



function* AllPartnerProfil() {
   yield takeEvery(PARTNER_PROFIL_GET, PartnerProfil)
}

export default AllPartnerProfil



