import { takeEvery, put, call } from "redux-saga/effects"
import axios from "axios";
import { API_SERVICE_QUERY, API_SERVICE_UPDATE } from "ApiConfig";
import {jwtDecode} from "jwt-decode"
import { 
  
    JOBS_VIEW_GET 
        } from "./actionTypes"

import { Jobs_view_SET } from "./actions";



function* View ({id}) {

  
   async function API() {
       var partner = jwtDecode(localStorage.getItem("authUser"))
     
 
       const response = await axios
          .post(`${API_SERVICE_QUERY}JobsView`, {
             id: id,
             token: JSON.parse(localStorage.getItem("authUser"))
          })
          .then(response => response)
          .catch(err => {
             console.log("Bitmedi")
             // LoginButtonStatus(dispatch,"a")
          })
 
 
       return response.data
    }
 
    // Login İşleminde Sunucudan Dönen Yanıt
    const datam = yield call(API)

 
    yield put(Jobs_view_SET(datam)) 

  
}




function* JobsView() {


    yield takeEvery(JOBS_VIEW_GET, View)

 }
 
 export default JobsView