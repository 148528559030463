import React from "react";
import { Row, Col, Card, CardBody ,CardTitle,Table} from "reactstrap";

import PropTypes from 'prop-types';


//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect} from "react-redux";

import withRouter from "components/Common/withRouter";


import profileImg from "../../assets/images/profile-img.png";
import { PROFIL_IMG } from "ApiConfig";

const WelcomeComp = ({info}) => { 

 

var profilImg = info.profilimage.picture_path;
  


    return (
      <React.Fragment>
        <Card className="overflow-hidden">
          <div className="bg-primary bg-soft">
            <Row>
              <Col xs="7">
                <div className="text-primary p-3">
                  <h5 className="text-primary"> {info.fullName} Profile </h5>
                  <p>THE WAWY </p>
                </div>
              </Col>
              <Col xs="5" className="align-self-end">
                <img src={profileImg} alt="" className="img-fluid" />
              </Col>
            </Row>
          </div>
          <CardBody className="pt-0">
            <Row>
              <Col sm="4">
                <div className="avatar-md profile-user-wid mb-1">
                  <img
                    src={`${PROFIL_IMG}${profilImg}`}
                    alt=""
                    className="img-thumbnail rounded-circle"
                  />
                </div>
        
              </Col>

              <Col sm="8">
               <Row>
               <div className="mt-2">
                <h5 className="font-size-14">Services</h5>
                </div>
                
                




                {Object.values(info.services).map((item, index) => {
        if (item.status) {
       
          return (
          

<Col key={index} sm="6"><span className="badge badge-soft-primary">{item.name}</span>{" "}</Col>
         
          );
        }
        return null;
      })}








                
               </Row>
               
              </Col>

     
            </Row>
          </CardBody>
        </Card>


        <Card>
                  <CardBody>
                    <CardTitle className="mb-4 h4">
                      Personal Summery Information
                    </CardTitle>

                    <p className="text-muted mb-4">
                   
                    <div dangerouslySetInnerHTML={{ __html:info.aboutus.summary }}></div>

                    </p>
                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Full Name :</th>
                            <td>{info.fullName}</td>
                          </tr>
                          <tr>
                            <th scope="row">Mobile :</th>
                            <td>{info.phone}</td>
                          </tr>
                          <tr>
                            <th scope="row">E-mail :</th>
                            <td>{info.email}</td>
                          </tr>
                          <tr>
                            <th scope="row">Location :</th>
                            <td>London / UK</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>


      </React.Fragment>
    )
 
    }





export default WelcomeComp;
