import { takeEvery, put, call } from "redux-saga/effects"
import axios from "axios";
import { API_SERVICE_QUERY, API_SERVICE,API_SERVICE_UPDATE } from "ApiConfig";
import {jwtDecode} from "jwt-decode"
import { 
    OFFER_CREATE_GET,
    OFFER_CREATE_SET,
    OFFER_DELETE,
    OFFER_GET,
    OFFER_SET,
    OFFER_SINGLE_GET,
    OFFER_UPTADE_GET,
    OFFER_PARTNER_ACCEPT,
    OFFER_TEAM_LEADER,

    
        } from "./actionTypes"

import { Offer_Create_SET, Offer_SET, Offer_Single_SET } from "./actions";

import { notify } from "store/notification/action";



function* OfferCreate ({OfferData}) {



    async function API() {
        var client = jwtDecode(localStorage.getItem("authUser"))
        var id = client.client_id
        var client_name = client.client_name
        var city = client.city
        var county = client.county
        var postcode = client.postcode
  


        const response = await axios
           .post(`${API_SERVICE}OfferCreate`, {
              client_id: id,
              token: JSON.parse(localStorage.getItem("authUser")),
              OfferData:OfferData,
              client_name:client_name,
              city:city,
              county:county,
              postcode:postcode
           })
           .then(response => response)
           .catch(err => {
              console.log("Bitmedi")
              // LoginButtonStatus(dispatch,"a")
           })
  
  
        return response.data
     }
  
     // Login İşleminde Sunucudan Dönen Yanıt
     const datam = yield call(API)


     yield put(notify(datam))
  //   yield put(Offer_Create_SET(datam)) 

}


function* Offers () {
   console.log("Geldim")
    async function API() {
        var client = jwtDecode(localStorage.getItem("authUser"))
        var id = client.client_id
  
        const response = await axios
           .post(`${API_SERVICE_QUERY}MyOfferList`, {
              client_id: id,
              token: JSON.parse(localStorage.getItem("authUser"))
           })
           .then(response => response)
           .catch(err => {
              console.log("Bitmedi")
              // LoginButtonStatus(dispatch,"a")
           })
  
  
        return response.data
     }
  
     // Login İşleminde Sunucudan Dönen Yanıt
     const datam = yield call(API)
  

     yield put(Offer_SET(datam)) 
}


function* Offer_Single (Offer_id) {

    async function API() {
        var client = jwtDecode(localStorage.getItem("authUser"))
        var id = client.client_id
  
        const response = await axios
           .post(`${API_SERVICE_QUERY}SingleOfferList`, {
              client_id: id,
              offerId:Offer_id.id,
              token: JSON.parse(localStorage.getItem("authUser"))
           })
           .then(response => response)
           .catch(err => {
              console.log("Bitmedi")
              // LoginButtonStatus(dispatch,"a")
           })
  
  
        return response.data
     }
  
     // Login İşleminde Sunucudan Dönen Yanıt
     const datam = yield call(API)
  
 
     yield put(Offer_Single_SET(datam)) 
}



function* OfferUpdate ({OfferDataUpdate}) {



    async function API() {
        var client = jwtDecode(localStorage.getItem("authUser"))
     
  
        const response = await axios
           .post(`${API_SERVICE_UPDATE}OfferUpdate`, {
              token: JSON.parse(localStorage.getItem("authUser")),
              OfferData:OfferDataUpdate,
             
           })
           .then(response => response)
           .catch(err => {
              return({ status: false, title: "Server Error",message:"The server is not responding or the operation has timed out. Please try later.",type:"error",action:"update", default:""  })
              // LoginButtonStatus(dispatch,"a")
           })
  
  
        return response.data
     }
  
     // Login İşleminde Sunucudan Dönen Yanıt
     const datam = yield call(API)

     yield put(notify(datam)) 

}




function* OfferDelete ({Offer_id}) {



    async function API() {
        
     
  
        const response = await axios
           .post(`${API_SERVICE_UPDATE}OfferDelete`, {
              token: JSON.parse(localStorage.getItem("authUser")),
              OfferId:Offer_id,
             
           })
           .then(response => response)
           .catch(err => {
              return({ status: false, title: "Server Error",message:"The server is not responding or the operation has timed out. Please try later.",type:"error",action:"update", default:""  })
              // LoginButtonStatus(dispatch,"a")
           })
  
  
        return response.data
     }
  
     // Login İşleminde Sunucudan Dönen Yanıt
     const datam = yield call(API)


     yield put(notify(datam)) 

}



function* OfferPartnerAccept ({Offer_id,Partner_id}) {


    async function API() {
        
        const client = jwtDecode(localStorage.getItem("authUser"))

        const response = await axios
           .post(`${API_SERVICE_UPDATE}OfferPartnerAccept`, {
              token: JSON.parse(localStorage.getItem("authUser")),
              OfferId:Offer_id,
              Partner_id:Partner_id,
              client_id:client.client_id
             
           })
           .then(response => response)
           .catch(err => {
              return({ status: false, title: "Server Error",message:"The server is not responding or the operation has timed out. Please try later.",type:"error",action:"update", default:""  })
              // LoginButtonStatus(dispatch,"a")
           })
  
  
        return response.data
     }
  
     // Login İşleminde Sunucudan Dönen Yanıt
     const datam = yield call(API)

     yield put(notify(datam)) 

}



function* OfferTeamLeader ({Offer_id,Partner_id}) {


   async function API() {
       
    
 
       const response = await axios
          .post(`${API_SERVICE_UPDATE}OfferTeamLeader`, {
             token: JSON.parse(localStorage.getItem("authUser")),
             OfferId:Offer_id,
             Partner_id:Partner_id
            
          })
          .then(response => response)
          .catch(err => {
             return({ status: false, title: "Server Error",message:"The server is not responding or the operation has timed out. Please try later.",type:"error",action:"update", default:""  })
             // LoginButtonStatus(dispatch,"a")
          })
 
 
       return response.data
    }
 
    // Login İşleminde Sunucudan Dönen Yanıt
    const datam = yield call(API)

    yield put(notify(datam)) 

}






function* OfferSaga() {

    yield takeEvery(OFFER_CREATE_GET, OfferCreate)
    yield takeEvery(OFFER_GET, Offers)
    yield takeEvery(OFFER_SINGLE_GET, Offer_Single)
    yield takeEvery(OFFER_UPTADE_GET, OfferUpdate)
    yield takeEvery(OFFER_DELETE, OfferDelete)
    yield takeEvery(OFFER_PARTNER_ACCEPT, OfferPartnerAccept)
    yield takeEvery(OFFER_TEAM_LEADER, OfferTeamLeader)

    
 }

 
 
 export default OfferSaga