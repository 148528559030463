import { 

    OFFER_TEAM_LEADER_SET,

        } from "./actionTypes"


const initialState = {
    error:"",
    status: false,
}




      


const OfferTeamLeaderGetReducers = (state = initialState,action) => {

  switch (action.type) {
    
            case OFFER_TEAM_LEADER_SET:
              return {
                ...state,
                action
              };
   
      default:
        return { ...state };
  }


}
        

export default OfferTeamLeaderGetReducers




