import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, LOGIN_CHECK } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
import { Navigate } from "react-router-dom";
import React from "react"


import {API_SERVICE_LOGIN,API_SERVICE_QUERY} from "../../../ApiConfig"


import axios from "axios";

function* loginUser({ payload: { user, history } }) {


  try {

  
    async function Login(e) {
      const response = await axios
        .post(`${API_SERVICE_LOGIN}PartnerLoginSecurity`, {
          email: e.email,
          password: e.password,
        })
        .then(response => response)
        .catch(err => {
          
          // LoginButtonStatus(dispatch,"a")
        });

      if (response.data.status) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("authUser", JSON.stringify(response.data.token));
       
        return response.data;
      } else {
      
        return response.data;
      }
    }

        // Login İşleminde Sunucudan Dönen Yanıt. Sunucuya baglandık ve yield ile cevabı bekledik.
        const datam = yield call(Login, user); // Login fonksiyonuna parametre göndermek için "," ile ayırıp göndermek istedigimiz parametreyi yazıyoruz.
        // true  veya false döner
        if (datam.status) {
         
          yield put(loginSuccess(datam));
          history("/Dashboard");
        }

  } catch (error) {
    yield put(apiError(error));
  }
}

// function* logoutUser({ payload: { history } }) {
//   try {
//     localStorage.removeItem("authUser");

//     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
//       const response = yield call(fireBaseBackend.logout);
//       yield put(logoutUserSuccess(response));
//     }
//     console.log("history",history)
//     history("/login");
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    history('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}



function* loginCheck() {
  async function API() {
 
    const response = await axios
       .post(`${API_SERVICE_QUERY}LoginCheck`, {
         token: JSON.parse(localStorage.getItem("authUser")), 
       })
       .then(response => response)
       .catch(err => {
          console.log("LoginCheck Hata")
          // LoginButtonStatus(dispatch,"a")
       })


    return response.data
 }

 // Login İşleminde Sunucudan Dönen Yanıt
 const datam = yield call(API)


if(!datam.status){ 
  if(localStorage.getItem("token")) {
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
    localStorage.removeItem("DataDashboard");
    return (
        <Navigate to={{ pathname: "/login" }} />
    );
  }

} else { console.log("Sorun Yok")}

 
 //yield put(check(datam)) 
}



function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(LOGIN_CHECK, loginCheck);
}

export default authSaga;
