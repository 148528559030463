import { Navigate,useLocation  } from "react-router-dom";
import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
const Authmiddleware = (props) => {

  const dispatch = useDispatch()
  const location = useLocation();

  useEffect(() => {dispatch({type:"LOGIN_CHECK"}) }, [location])


  if (!localStorage.getItem("authUser") || !localStorage.getItem("token") ) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
};

export default Authmiddleware;



