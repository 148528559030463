import PropTypes from "prop-types";
import React, {  useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";

// Redux
import { connect } from "react-redux";
import withRouter from "components/Common/withRouter";
//i18n
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useLocation } from 'react-router-dom';
import { Dna } from 'react-loader-spinner'

import Welcome from "components/Pofile/Welcome";
import Services from "components/Pofile/Services";
import Stats from "components/Pofile/Stats";
import AboutUs from "components/Pofile/AboutUs";
import Education from "components/Pofile/Educations";
import CertificatesCard from "components/Pofile/CertificatesCard";
import Comments from "components/Pofile/Comments";
import {Partner_Profil_GET} from "store/PartnerProfil/actions";


const PartnerDetail =  () => {



 const location = useLocation();
 const _id = location.pathname.split('/').pop();

  const [ProfilData, setPorfilData] = useState()
  const dispatch = useDispatch();

  useEffect(() => {
 

  
    dispatch(Partner_Profil_GET(_id));

   
  
    
}, [dispatch,_id])

const PartnerProfil = useSelector(state => state?.PartnerProfil?.action?.data?.PartnerProfil);
 
  useEffect(() => {
    setPorfilData(PartnerProfil);
}, [PartnerProfil]);







  return (
    
    <React.Fragment>
       
       {ProfilData ? 
     
     <div className="page-content">
       
       <Container fluid>
      
         <Breadcrumb title="Details" breadcrumbItem="Partner Profile" />

       <Row>
        
       <Col lg="4">
       <Welcome info={ProfilData} />
       <Services info={ProfilData}/>
    
        </Col>
   <Col lg="8">
       <AboutUs  info={ProfilData} />
       <Education  info={ProfilData} />
        <CertificatesCard  info={ProfilData} />
    </Col>  
       
       </Row>


       </Container>
     </div>  : 
           <div className="page-content">
           <Container fluid>
   
   
             <Row style={{ height: "100vh" }} >
               <Col lg="12">
   
               </Col>
               <Row>
                 <Col lg="4"></Col>
                 <Col lg="4" className="text-center">
                   <Dna
                     visible={true}
                     height="160"
                     width="160"
                     ariaLabel="dna-loading"
                     wrapperStyle={{}}
                     wrapperClass="dna-wrapper"
                   />
                 </Col>
                 <Col lg="4"></Col>
   
   
               </Row>
               <Col lg="12">
   
               </Col>
             </Row>
   
           </Container>
         </div>
     
     }
    </React.Fragment>
  );

}





export default PartnerDetail;



