import { 
  INFORM_API,
  INFORM_SET,
  INFORM_DELETE 
      } from "./actionTypes"



export const Inform_API = () => ({
  type:INFORM_API,
})

export const Inform_SET = informList => ({
  type:INFORM_SET,
  informList,
})


export const Inform_DELETE = inform_id => ({
  type:INFORM_DELETE,
  inform_id,
})


