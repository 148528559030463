export const API_SERVICE_LOGIN     ="https://partner.thewawy.co.uk:3002/"
export const API_SERVICE           ="https://partner.thewawy.co.uk:3002/api/Insert/"
export const API_SERVICE_QUERY     ="https://partner.thewawy.co.uk:3002/api/Query/"
export const API_SERVICE_UPDATE    ="https://partner.thewawy.co.uk:3002/api/Update/"
export const API_FILES_UPLOAD      ="https://partner.thewawy.co.uk:3002/api/Upload/"




export const PROFIL_IMG      ="https://thewawy.co.uk/services/pictures/"
export const DOCUMNET_FILES    ="https://thewawy.co.uk/services/files/"