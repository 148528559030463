import {
    API_DASHBOARD_GET,
    API_DASHBOARD_SET,
    
} from "./actionTypes";

export const Dashboard_GET = () => ({
    type: API_DASHBOARD_GET,
   
});


export const Dashboard_SET = (datam) => ({
    type: API_DASHBOARD_SET,
    datam
    
});




