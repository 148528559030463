import { takeEvery, put, call } from "redux-saga/effects"
import axios from "axios";
import { API_SERVICE_QUERY, API_SERVICE_UPDATE } from "ApiConfig";
import { jwtDecode as jwtDecode } from 'jwt-decode';
import { 
  
    INFORM_API ,INFORM_DELETE
        } from "./actionTypes"

import { Inform_SET } from "./actions";
import { notify } from "store/notification/action";


function* informList () {

  
   async function API() {
       var partner = jwtDecode(localStorage.getItem("authUser"))
       var id = partner.partner_id
     
 
       const response = await axios
          .post(`${API_SERVICE_QUERY}InformList`, {
             partner_id: id,
             token: JSON.parse(localStorage.getItem("authUser"))
          })
          .then(response => response)
          .catch(err => {
             console.log("Bitmedi")
             // LoginButtonStatus(dispatch,"a")
          })
 
 
       return response.data
    }
 
    // Login İşleminde Sunucudan Dönen Yanıt
    const datam = yield call(API)

 
    yield put(Inform_SET(datam)) 

  
}


function* informDelete ({inform_id}) {

  
   async function API() {
       var partner = jwtDecode(localStorage.getItem("authUser"))
       var inform_id = inform_id
     
 
       const response = await axios
          .post(`${API_SERVICE_QUERY}InformDelete`, {
             partner_id: id,
             token: JSON.parse(localStorage.getItem("authUser"))
          })
          .then(response => response)
          .catch(err => {
             console.log("Bitmedi")
             // LoginButtonStatus(dispatch,"a")
          })
 
 
       return response.data
    }
 
    // Login İşleminde Sunucudan Dönen Yanıt
    const datam = yield call(API)

    yield put(notify(datam)) 
    

  
}




function* Inform_Saga() {


    yield takeEvery(INFORM_API, informList)
    yield takeEvery(INFORM_DELETE,informDelete)
 }
 
 export default Inform_Saga