import { takeEvery, put, call } from "redux-saga/effects"
import axios from "axios";
import { API_SERVICE_QUERY, API_SERVICE_UPDATE } from "ApiConfig";
import { jwtDecode as jwtDecode } from 'jwt-decode';
import { 
  
    JOBS_TEAM_GET 
        } from "./actionTypes"

import { Jobs_team_SET } from "./actions";



function* team () {
   
 
 async function API() {

   const  client = jwtDecode(localStorage.getItem("authUser"))


   const id = client.client_id

       const response = await axios
          .post(`${API_SERVICE_QUERY}JobsTeam`, {
             id: id,
             token: JSON.parse(localStorage.getItem("authUser"))
          })
          .then(response => response)
          .catch(err => {
             console.log("Bitmedi")
             // LoginButtonStatus(dispatch,"a")
          })
 
 
       return response.data
    }
 
    // Login İşleminde Sunucudan Dönen Yanıt
    const datam = yield call(API)

    yield put(Jobs_team_SET(datam)) 




  
}




function* JobsTeam() {


    yield takeEvery(JOBS_TEAM_GET, team)

 }
 
 export default JobsTeam