
import { CHECK,
  } from "./actionType";

    const initialState = {
        status:"",
        title:"",
        message:"",
        type:"",
        action:"",
        default:""
 }


 const CheckNotifyReducer = ( state=initialState,action) =>  {

       switch (action.type) {

          case CHECK :
            return {action}
           default:
           return { ...state };
       }


 }


 export default CheckNotifyReducer
