import { 
  OFFER_CREATE_GET,
  OFFER_CREATE_SET,
  OFFER_GET,
  OFFER_SET,
  OFFER_SINGLE_GET,
  OFFER_SINGLE_SET,
  OFFER_UPTADE_GET,
  OFFER_UPTADE_SET,
  OFFER_DELETE,
  OFFER_PARTNER_ACCEPT,
  OFFER_TEAM_LEADER,


      } from "./actionTypes"




export const Offer_Create_GET =  OfferData => ({
  type:OFFER_CREATE_GET,
  OfferData
})

export const Offer_Create_SET =  OfferData => ({
  type:OFFER_CREATE_SET,
  OfferData
})

export const Offer_GET = () => ({
  type:OFFER_GET,
})

export const Offer_SET = OfferData => ({
  type:OFFER_SET,
  OfferData,
})



export const Offer_Single_GET = (id) => ({
  type:OFFER_SINGLE_GET,
  id
})

export const Offer_Single_SET = OfferData => ({
  type:OFFER_SINGLE_SET,
  OfferData,
})


export const Offer_Update_GET =  OfferDataUpdate => ({
  type:OFFER_UPTADE_GET,
  OfferDataUpdate
})

export const Offer_Update_SET =  OfferDataUpdate => ({
  type:OFFER_UPTADE_SET,
  OfferDataUpdate
})


export const Offer_Delete =  Offer_id => ({
  type:OFFER_DELETE,
  Offer_id
})


export const Offer_Partner_Accept =  (Offer_id,Partner_id) => ({
  type:OFFER_PARTNER_ACCEPT,
  Offer_id,
  Partner_id
})
 

export const Offer_Team_Leader_Insert =  (Offer_id,Partner_id) => ({
  type:OFFER_TEAM_LEADER,
  Offer_id,
  Partner_id
})





