
import { NOTIFY,
  } from "./actionType";

    const initialState = {
        status:"",
        title:"",
        message:"",
        type:"",
        action:"",
        default:""
 }


 const NotifyReducer = ( state=initialState,action) =>  {

       switch (action.type) {

          case NOTIFY :
            return {action}
           default:
           return { ...state };
       }


 }


 export default NotifyReducer
