import React, { useEffect, useState } from "react";
// import { Link, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";


import {
    Badge,
    Col,
    Container,
    Row,
    Table,
    Button,
    Tooltip,
    UncontrolledDropdown,
    CardBody,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Card,

} from "reactstrap";


//Import Component
import Breadcrumbs from "components/Common/Breadcrumb";

import { Offer_Partner_Accept,Offer_Team_Leader_Insert } from "store/offer/actions";
import { useLocation } from 'react-router-dom';
import { Offer_Single_GET } from "store/offer/actions";
import {Offer_TeamLeader_GET} from "store/teamleader/actions";
import { useDispatch, useSelector } from 'react-redux';
import leader from "../../assets/lottie/leader3.json"; // Lottie JSON dosyasının yolu
import Lottie from "lottie-react";
//Import Image
import { Dna } from 'react-loader-spinner'
import Swal from 'sweetalert2'
import { PROFIL_IMG } from "ApiConfig";

import toast, { Toaster } from 'react-hot-toast'







const OfferView = () => {



    //meta title
    document.title = "Offer View | The Wawy - Dashboard ";

    const dispatch = useDispatch()
    const [tooltipOpen, setTooltipOpen] = useState(new Array(0).fill(false));

    // Create a toggle function that takes an index
    const toggle = (i) => {
      const newArray = [...tooltipOpen];
      newArray[i] = !newArray[i];
      setTooltipOpen(newArray);
    };


    const location = useLocation();
    const _id = location.pathname.split('/').pop();
    const [controlDispatch, SetControlDispatch] = useState(true);
    const [loading, setLoading] = useState(true);

    const [ApiData, setApiData] = useState("default");

    useEffect(() => {
        dispatch(Offer_Single_GET(_id));
        dispatch(Offer_TeamLeader_GET(_id));
    }, [dispatch, _id,controlDispatch]);







    const myReduxData = useSelector(state => state.OfferReducers.action);
    const teamleader = useSelector(state => state.OfferTeamLeaderGetReducers.action);
   

  
    function getFullNamesAndPictures(myReduxData,teamleader) {
        let fullNames = [];
   
        if (myReduxData && myReduxData.OfferData && myReduxData.OfferData.offers && myReduxData.OfferData.offers[0] && myReduxData.OfferData.offers[0].applications) {
          const applications = myReduxData.OfferData.offers[0].applications;
      
          applications.forEach(application => {
    
            if (application.partner && application.partner.fullName ) {
              fullNames.push(application.partner);
            }
          });
        }

      const id = myReduxData ? myReduxData.OfferData.offers[0]._id : null;
      const applications_accept = myReduxData ? myReduxData.OfferData.offers[0].applications_accept: null;

      const temaleaderID =teamleader?.teamleader?.teamsleader?.teamleader
        return (
       <>  









{fullNames.map((item, index) => (
  <Col lg="6" key={index}>
    {applications_accept && applications_accept.includes(item._id) ? (
      // Eğer item._id, applications_accept dizisi içinde varsa bu kod bloğu çalışır
      <div className="alert alert-success p-3 d-flex mb-3 rounded">
                      <img src={`${PROFIL_IMG}${item.profilimage.picture_path}`} alt="" className="avatar-sm rounded me-3" />
               <div className="flex-grow-1">
                   <h5 className="font-size-15 mb-2"><a href="candidate-overview" className="text-body">{item.fullName}</a> {item.gender=="Man" ?   <span className="badge badge-soft-info">{item.gender}</span> :  <span className="badge badge-soft-danger">{item.gender}</span>} </h5>
                   <p className="mb-0 text-muted"><i className="bx bx-map text-body align-middle"></i> {item?.address} / {item?.postcode} / {item?.city}</p>
               </div>
               <div className="flex">
               {
teamleader ?


 item._id == temaleaderID
? <Lottie
    animationData={leader}
    loop
    autoplay={true}
    renderer="svg"
    style={{ width: '50px', marginTop:"-10px" }}
  />
: null


: null
}
                   <UncontrolledDropdown>
                       <DropdownToggle className="btn btn-soft-primary" type="button" id="dropdownMenuButton11">
                           <i className='bx bx-dots-vertical-rounded'></i>
                       </DropdownToggle>
                       <DropdownMenu aria-labelledby="dropdownMenuButton11">
                           <li><DropdownItem href={`/PartnerDetail/${item._id}`}>View Details</DropdownItem></li>
                           
                           <li><DropdownItem onClick={() => (AlreadyApply())}>Accepted</DropdownItem></li>
                           <li><DropdownItem onClick={() => (handleTeamLeaderApply(id,item._id))}>Team Leader</DropdownItem></li>
                       </DropdownMenu>
                   </UncontrolledDropdown>
               </div>
      </div>
    ) : (
      // Eğer item._id, applications_accept dizisi içinde yoksa bu kod bloğu çalışır
      <div className="bg-light p-3 d-flex mb-3 rounded">
                       <img src={`${PROFIL_IMG}${item.profilimage.picture_path}`} alt="" className="avatar-sm rounded me-3" />
                 <div className="flex-grow-1">
                     <h5 className="font-size-15 mb-2"><a href="candidate-overview" className="text-body">{item.fullName}</a> {item.gender=="Man" ?   <span className="badge badge-soft-info">{item.gender}</span> :  <span className="badge badge-soft-danger">{item.gender}</span>} </h5>
                     <p className="mb-0 text-muted"><i className="bx bx-map text-body align-middle"></i> {item?.address} / {item?.postcode} / {item?.city}</p>
                 </div>
                 <div>

                     <UncontrolledDropdown>
                         <DropdownToggle className="btn btn-soft-primary" type="button" id="dropdownMenuButton11">
                             <i className='bx bx-dots-vertical-rounded'></i>
                         </DropdownToggle>
                         <DropdownMenu aria-labelledby="dropdownMenuButton11">
                             <li><DropdownItem href={`/PartnerDetail/${item._id}`}>View Details</DropdownItem></li>
                             <li><DropdownItem onClick={() => (handleApply(id,item._id))}>Accept</DropdownItem></li>
                           
                         </DropdownMenu>
                     </UncontrolledDropdown>
                 </div>
      </div>
    )}
  </Col>
))}









        </>
        )


      }









      function getFull(myReduxData) {
        let fullNames = [];
      
        if (myReduxData && myReduxData.OfferData && myReduxData.OfferData.offers && myReduxData.OfferData.offers[0] && myReduxData.OfferData.offers[0].applications) {
          const applications = myReduxData.OfferData.offers[0].applications;
      
          applications.forEach(application => {
            if (application.partner && application.partner.fullName && application.partner.profilimage) {
              fullNames.push({fullName: application.partner.fullName, image: application.partner.profilimage});
            }
          });
        }
      
        return (
          <div>
                     <div className="avatar-group">
                                                {fullNames.map((team, key) =>
                                                    !team.image || team.image !== "Null" ? (
                                                        <React.Fragment key={key}>
                                                        
                                                            <div className="avatar-group-item">
                                                                <Link to="#" className="d-inline-block" id={'Tooltip-' + key} 
                                                                    onMouseEnter={() => toggle(key)}
                                                                    onMouseLeave={() => toggle(key)}
                                                                >
                                                                    <img src={`${PROFIL_IMG}${team.image.picture_path}`} className="rounded-circle avatar-xs" alt="" />

                                                                    <Tooltip
                                                                                             placement="top"
                                                                                             target={'Tooltip-' + key}
                                                                                             isOpen={tooltipOpen[key]}
                                                                        >
                                                                           {team.fullName}
                                                                        </Tooltip>
                                                                </Link>
                                                            </div>
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment key={key}>
                                                            <div className="avatar-group-item">
                                                                <Link to="#" className="d-inline-block" id={'Tooltip-' + key}
                                                                    onMouseEnter={() => toggle(key)}
                                                                    onMouseLeave={() => toggle(key)}
                                                                >
                                                                    <div className="avatar-xs">
                                                                        <span className={`avatar-title rounded-circle bg-success} text-white font-size-16`} >{"A"}  </span>
                                                                     
                                                                        <Tooltip
                                                                            placement="top"
                                                                            target={'Tooltip-' + key}
                                                                            isOpen={tooltipOpen[key]}
                                                                        >
                                                                           {team.fullName}
                                                                        </Tooltip>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                )}

                                    



                                            </div>
          </div>
        );
      }







    const offers = useSelector(state => state.OfferReducers.action);
    const [toastStatus, setToastStatu] = useState(false)
    const notify = useSelector(state => state.NotifyReducer.action);

 



    useEffect(() => {
        if (notify) {
         
      
          const shouldSucceed = notify.payload.status; // veya false
      
          const simulateFetchData = (shouldSucceed) => {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                if (shouldSucceed) {
                  resolve("Data successfully fetched");
                } else {
                  reject(new Error("Error when fetching data"));
                }
              }, 1000); // 2 saniye sonra resolve veya reject
            });
          };
      
          toast.promise(simulateFetchData(shouldSucceed), {
            loading: 'Loading',
            success: (data) => {
              // Başlık ve mesajı kullanarak bir string oluşturabilir veya başka bir şekilde işleyebilirsiniz.
              return (
                <>
                  <div>
                    <strong>{notify.payload.title}</strong> <br />{notify.payload.message}
                  </div>
                </>
              );
            },
            error: (data) => {
              // Başlık ve mesajı kullanarak bir string oluşturabilir veya başka bir şekilde işleyebilirsiniz.
              return (
                <>
                  <div>
                    <strong>{notify.payload.title}</strong> <br />{notify.payload.message}
                  </div>
                </>
              );
            },
          }, {
            success: {
              duration: 3000,
            },
          });
        }
      }, [notify]);




    var MyOffers = []

    if (offers && offers.OfferData) { var MyOffers = offers.OfferData.offers }

    // Team partner ekleme işlemi gerçekleşir
    const handleApply = (id,partner_id) => {

   

    
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Add it!'

        }).then((result) => {
            if (result.isConfirmed) {
               /* Swal.fire(
                    'Added!',
                    'Your request has been applied.',
                    'success'
                  ) */
                dispatch(Offer_Partner_Accept(id,partner_id));
                setToastStatu(true)
               if(controlDispatch) {SetControlDispatch(false)} else {SetControlDispatch(true)}
                

            } else {// setApply(false)
             }
        })




    }




   // Team lider ekleme işlemi gerçekleşir
    const handleTeamLeaderApply = (id,partner_id) => {

   

    
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, This Partner Team Leader!'

        }).then((result) => {
            if (result.isConfirmed) {
               /* Swal.fire(
                    'Added!',
                    'Your request has been applied.',
                    'success'
                  ) */
                dispatch(Offer_Team_Leader_Insert(id,partner_id));
                setToastStatu(true)
               if(controlDispatch) {SetControlDispatch(false)} else {SetControlDispatch(true)}
                

            } else {// setApply(false)
             }
        })




    }
    

     const AlreadyApply = () => {   
     
        setToastStatu(true)
        
            const shouldSucceed = false; // veya false
        
            const simulateFetchData = (shouldSucceed) => {
              return new Promise((resolve, reject) => {
                setTimeout(() => {
                  if (shouldSucceed) {
                    resolve("Data successfully fetched");
                  } else {
                    reject(new Error("Error when fetching data"));
                  }
                }, 1000); // 2 saniye sonra resolve veya reject
              });
            };
        
            toast.promise(simulateFetchData(shouldSucceed), {
              loading: 'Loading',
              success: (data) => {
                // Başlık ve mesajı kullanarak bir string oluşturabilir veya başka bir şekilde işleyebilirsiniz.
                return (
                  <>
                    <div>
                      <strong>{notify.payload.title}</strong> <br />{notify.payload.message}
                    </div>
                  </>
                );
              },
              error: (data) => {
                // Başlık ve mesajı kullanarak bir string oluşturabilir veya başka bir şekilde işleyebilirsiniz.
                return (
                  <>
                    <div>
                      <strong>{"Already added"}</strong> <br />{"The candidate has already been added to the team."}
                    </div>
                  </>
                );
              },
            }, {
              success: {
                duration: 3000,
              },
            });
        




      }
   


    return (
        <React.Fragment>
{toastStatus ? <Toaster position="top-right" reverseOrder={false} /> : <></>}
<div className="page-content" style={{ backgroundColor: "#f2f2f5" }}>
                <Container fluid>

           



                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Offers" breadcrumbItem="Offer View" />




                    <Row>
          

          <Col lg="12">
              <Card>

                  <CardBody>

                      <Col lg="12" className=" mt-1">

                          <Col lg="12">
                              
                                  <div className=" mb-2">
                                      <Row>
                                          <Col lg="9" sm="8">
                                              <div className="p-4">
                                                  <h5 className="text-primary">{MyOffers[0] ? MyOffers[0].job_title : <></>}</h5>
                                                
                                                  {MyOffers[0] ? <div dangerouslySetInnerHTML={{ __html: MyOffers[0].job_about }}></div> : <></>  }
                                                  <div className="text-muted">
                                                      <p className="mb-1">
                                                          <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                                                          {MyOffers[0] ? MyOffers[0].days : <></>} Days
                                                      </p>
                                                      <p className="mb-1">
                                                          <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                                                          Price: {MyOffers[0] ?  MyOffers[0].fee.fee : <></>}£
                                                      </p>
                                                      <p className="mb-0">
                                                          <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                                                          {MyOffers[0] ?  MyOffers[0].create_date : <></>}
                                                          
                                                      </p>
                                                  </div>
                                              </div>
                                          </Col>
                                          <Col lg="3" sm="4">
                                              <div>
                                                  <img src={"features"} alt="" height={200} />
                                              </div>
                                          </Col>
                                      </Row>
                                  </div>
                             

                          </Col>



                          <Row>
                          <Col lg="12"><div className="alert alert-info fade show" role="alert">List of candidates who applied to your job offer!</div></Col>
                          {getFullNamesAndPictures(myReduxData,teamleader)}

                          </Row>








                      </Col>
                  </CardBody>
              </Card>
          </Col>


      </Row>


                   
                   
              
                     


                </Container>
            </div>
        </React.Fragment>
    );
};

export default OfferView;


