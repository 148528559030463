import React, { useState, useEffect } from "react";

import {
    Col,
    Container,

    Row,
    Badge,
    Card,
    CardBody,
    UncontrolledTooltip,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Form,
    Input,
    FormFeedback,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    Button

} from "reactstrap";
import Select from "react-select";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";


import { useDispatch } from "react-redux";
import MyComponent from "./myData";
import "react-perfect-scrollbar/dist/css/styles.css";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import Lottie from 'lottie-react';
import addLottie from "../../assets/lottie/add3.json"; // Lottie JSON dosyasının yolu

import animationteams from "../../assets/lottie/planning.json"; // Lottie JSON dosyasının yolu

import teamanimation from "../../assets/lottie/team5.json"; // Lottie JSON dosyasının yolu
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

//import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Jobs_team_GET } from "../../store/team/actions";
;
import { jwtDecode as jwtDecode } from 'jwt-decode';
import AvatarModule from "./components/avatarModule";
import { Schedule_SET } from "store/actions";
import toast, { Toaster } from 'react-hot-toast'





const dayOptionGroup = [
    {
        label: "Days",
        options: [
            { label: "Monday", value: "Monday" },
            { label: "Tuesday", value: "Tuesday" },
            { label: "Wednesday", value: "Wednesday" },
            { label: "Thursday", value: "Thursday" },
            { label: "Friday", value: "Friday" },
            { label: "Saturday", value: "Saturday" },
            { label: "Sunday", value: "Sunday" },
        ]
    }
];








const Teams = () => {

    const dispatch = useDispatch();
    const  partner = jwtDecode(localStorage.getItem("authUser"))
    const id = partner.partner_id
    // Modal Değişkenleri Shift
    const [modal, setModal] = React.useState(false);
    const [friend, setFriend] = React.useState({ everytime: true, days: [], className: "badge-soft-info me-1 rounded-b-sm badge bg-secondary" });




    // Create Modal Değişkenleri
    const [CreatModal, setCreatModal] = React.useState(false);
    const [teams, setTeams] = React.useState([]);
    const [selectedMulti, setselectedMulti] = useState(null);
    const [nameColor, setNameColor] = React.useState("");
    const [nameFriend, setNameFriend] = React.useState("");
    const [friendID, setFriendID] = React.useState("");
    const [whatsappdata, setWhatsappdata] = React.useState([]);
    const [updateTeamCheck, setUpdateTeamCheck] = React.useState(false);



    /*------------------------USERS ve JOB ÇEKİYORUZ BAŞLAR------------------------------*/

    useEffect(() => {

        dispatch(Jobs_team_GET());
  

    }, [])


    //  Apiden gelen verileri alıyoruz ve state'e atıyoruz  
    const apiData = useSelector(state => state.team?.action?.JobsData);
    const status = useSelector(state => state.team?.action);
    const [jobs, setJobs] = useState([]);
    const [users, setUsers] = useState([]);
    const [teamleader, setTeamleader] = useState([]);
    const [mystatus, setMyStatus] = React.useState("");
    const [toastStatus, setToastStatus] = useState(false)
    useEffect(() => {
        if (apiData) {
            setJobs(apiData.jobs);
            setUsers(apiData.users[0]);
            setMyStatus(apiData.status);
            setTeamleader(apiData.temaleader);
         
           if(apiData.team?.schedule) { setTeams(apiData.team?.schedule);  } else {console.log("apiData böyle bir veri yok") }
          
        }
    }, [apiData]);













    const toggle = () => {
        if (modal) {
            setModal(false);

        } else {
            setModal(true);
        }
    };

    const toggleCreate = () => {
        if (CreatModal) {
            setCreatModal(false);

        } else {
            setCreatModal(true);
        }
    };


    //meta title
    document.title = "Teams | Thewawy - Client  Dashboard";







    /* 
    
    
    addTeamFunction "teams" dizisine yeni bir eleman ekler veya var olan bir elemanı günceller. Eğer nameFriend isminde bir eleman varsa,
    bu elemanı günceller. Eğer nameFriend isminde bir eleman yoksa, yeni bir eleman ekler. Eğer nameFriend ve nameColor boşsa, hata mesajı gösterir.
    Eğer nameFriend ve nameColor doluysa, yeni bir eleman ekler veya var olan bir elemanı günceller ve başarılı bir mesaj gösterir.
    Örnek bir "teams" dizisi aşağıdaki gibidir:
    [
        {"name": "Sinem", "everytime": true, "date": [],"days": ["Monday","Tuesday", "Wednesday" ],"className": "badge-soft-success me-1 rounded-b-sm badge bg-secondary" },
        {"name": "Roza", "everytime": true,"date": [],"days":   ["Saturday","Sunday"],"className": "badge-soft-danger me-1 rounded-b-sm badge bg-secondary"}
    ]

    */

const addTeamFunction = () => {
        if (nameFriend != false && nameColor != false) {
          const selectedDays = selectedMulti.map(item => item.value);
          const teamIndex = teams.findIndex(team => team.name === nameFriend);
      
          if (teamIndex !== -1) {
            // Eğer nameFriend isminde bir eleman varsa, bu elemanı güncelle
            const updatedTeams = teams.map(team => {
              // Eğer gün, selectedDays içindeyse ve takımın adı nameFriend'e eşit değilse, bu günü çıkar
              const filteredDays = team.days.filter(day => !selectedDays.includes(day) || team.name === nameFriend);
              return { ...team, days: filteredDays };
            });
      
            updatedTeams[teamIndex] = { id: friendID, name: nameFriend, everytime: true, date: [], days: selectedDays, className: nameColor };
            setTeams(updatedTeams);
            setCreatModal(false);
            // toast(`${nameFriend}  Friend Updated Team`, { theme: "dark" });
            setUpdateTeamCheck(true);
          } else {
            // Eğer nameFriend isminde bir eleman yoksa, yeni bir eleman ekle
            const updatedTeams = teams.map(team => {
              // Eğer gün, selectedDays içindeyse, bu günü çıkar
              const filteredDays = team.days.filter(day => !selectedDays.includes(day));
              return { ...team, days: filteredDays };
            });
      
            setTeams([...updatedTeams, { id: friendID, name: nameFriend, everytime: true, date: [], days: selectedDays, className: nameColor }]);
            setCreatModal(false);
            // toast('New Friend Added Team', { theme: "dark" });
            setUpdateTeamCheck(true);
          }
        } else {
          toast.error('Please Select Name and Color', { theme: "dark" });
        }
      };




    function updateTeams2(Teams) {
        // Bulunduğumuz yılın tüm günlerini al
        const date = new Date();
        const year = date.getFullYear();

        // Yılın tüm günlerini bir diziye dönüştür
        const days = [];
        for (let month = 0; month < 12; month++) {
            const daysInMonth = new Date(year, month + 1, 0).getDate();
            for (let day = 1; day <= daysInMonth; day++) {
                days.push({ day, month, year });
            }
        }

        // Teams dizisini döngüye al
        for (const team of Teams) {
            // Eğer days doluysa, o günleri al ve her birine name ve className özelliklerini ekle
            if (team.days.length > 0) {
                for (const { day, month, year } of days) {
                    const dayOfWeek = new Date(year, month, day).getDay();
                    const dayName = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][dayOfWeek];

                    if (team.days.includes(dayName)) {
                        const formattedDate = `${day.toString().padStart(2, '0')}-${(month + 1).toString().padStart(2, '0')}-${year}`;
                        team.date.push(formattedDate);
                    }
                }
            }
        }
        // Ayarlanan Schedule'ı güncelle veritabanına gönder ve döndür
        dispatch(Schedule_SET(teamleader._id,Teams));
        setToastStatus(true)
        return Teams; // Teams dizisini döndür
    }





    const handleMulti = (selectedOptions) => {
        setselectedMulti(selectedOptions);
    };

    const handleCreate = () => {
        const updatedTeams = teams.map(team => ({ ...team, date: [] }));
        setTeams(updateTeams2(updatedTeams));
        setUpdateTeamCheck(false);
    };



    const updateTeamShift = () => {

        setWhatsappdata([...whatsappdata, { Date: friend.date, Name: friend.name, Phone: friend.phone }]);
        // teams dizisinde friend.name ile aynı isme sahip elemanı bul
        let teamIndex = teams.findIndex(team => team.name === friend.name);

        if (teamIndex !== -1) {
            // Eğer bu isimde bir eleman varsa, bu elemanın date özelliğini friend.date ile değiştir
            let newDates = teams[teamIndex].date;
            friend.date.forEach(date => {
                if (!newDates.includes(date)) {
                    newDates.push(date);
                }
            });
            teams[teamIndex].date = newDates;
        } else {
            // Eğer bu isimde bir eleman yoksa, bu elemanı ekle
            teams.push({ name: friend.name, date: [...friend.date], className: friend.className, days: friend.days });
            teamIndex = teams.length - 1; // Yeni eklenen elemanın indeksini al
        }

        // teams dizisindeki diğer elemanların date özelliklerinde friend.date ile aynı değerler varsa, bu değerleri sil
        teams.forEach((team, index) => {
            if (index !== teamIndex) {
                team.date = team.date.filter(date => !friend.date.includes(date));
            }
        });

        // teams dizisini güncelle
        setTeams([...teams]);
        // Ayarlanan Schedule'ı güncelle veritabanına gönder ve döndür
        dispatch(Schedule_SET(teamleader._id,[...teams]));

        setToastStatus(true)
        setModal(false);
    };



/* 
    function arraysEqual(a, b) {
        return a.length === b.length && a.every((val, index) => val === b[index]);
    }

*/


const notify = useSelector(state => state.NotifyReducer.action);

useEffect(() => {
    if (notify) {
   
      const shouldSucceed = notify.payload.status; // veya false
  
      const simulateFetchData = (shouldSucceed) => {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            if (shouldSucceed) {
              resolve("Data successfully fetched");
            } else {
              reject(new Error("Error when fetching data"));
            }
          }, 1000); // 2 saniye sonra resolve veya reject
        });
      };
  
      toast.promise(simulateFetchData(shouldSucceed), {
        loading: 'Loading',
        success: (data) => {


            setTimeout(() => {
                setToastStatus(false);
              }, 3000); // 2 saniye sonra setToastStatus(false) çalışır
          

          // Başlık ve mesajı kullanarak bir string oluşturabilir veya başka bir şekilde işleyebilirsiniz.
          return (
            <>
              <div>
                <strong>{notify.payload.title}</strong> <br />{notify.payload.message}
              </div>
            </>
          );
        },
        error: (data) => {
          // Başlık ve mesajı kullanarak toastStatus string oluşturabilir veya başka bir şekilde işleyebilirsiniz.
          return (
            <>
              <div>
                <strong>{notify.payload.title}</strong> <br />{notify.payload.message}
              </div>
            </>
          );
        },
      }, {
        success: {
          duration: 3000,
          
        },
        
      });

      
    }
  }, [notify]);


    return (
        <React.Fragment>
             {toastStatus ? <Toaster position="top-right" reverseOrder={false} /> : <></>}
            {mystatus ?
                <>
                  
                 
                    <div className="page-content">
                        <Container fluid>
                            {/* Render Breadcrumb */}
                            <Breadcrumbs title="Thewawy" breadcrumbItem="Teams" />

                            <Row className="d-flex ">





                                <Col xl={4} sm={6}>
                                    <Card>
                                        <CardBody>
                                            <div className="d-flex">



                                                <div className=" me-4">


                                                    <img className="img-thumbnail rounded-circle avatar-xl" alt="200x200" src={require("../../assets/images/users/avatar-1.jpg")} data-holder-rendered="true" />

                                                </div>
                                                <div className="flex-grow-1 overflow-hidden">
                                                    <h5 className="text-truncate font-size-15">
                                                        <Link to={`/projects-overview/1`} className="text-dark">{jobs[0].client_name}</Link>
                                                    </h5>
                                                    <p className="text-muted mb-4">{jobs[0].job_title}</p>
                                                    <div className="avatar-group">
                                             <AvatarModule  avatar={jobs} />
                                                   {teamleader && teamleader.teamleader === id ? 
                                                        <React.Fragment >
                                                            <div style={{ border: "2px solid #50a5f1" }} className="avatar-group-item  ">
                                                                <Link to="#!" onClick={() => setCreatModal(true)} className="d-inline-block" id={"member"}>
                                                                    <div className="avatar-xs">
                                                                        <span className={`avatar-title rounded-circle border  bg-white font-size-16`} >


                                                                            <Lottie
                                                                                animationData={addLottie}
                                                                                loop
                                                                                autoplay={true}
                                                                                renderer="svg" // İsteğe bağlı olarak svg veya canvas olarak belirleyebilirsiniz
                                                                                style={{ width: '300px', height: '100%', }} // Genişlik ve yükseklik değerlerini buradan belirleyebilirsiniz

                                                                            />


                                                                        </span>
                                                                        <UncontrolledTooltip placement="top" target={"member"} >{`Add Team Friend`}</UncontrolledTooltip>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </React.Fragment>
                                                    :<></>
                                                    }



                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                        <div className="px-4 py-3 border-top d-flex">
                                            <ul className="list-inline mb-0">
                                                <li className="list-inline-item me-3">
                                                    <Badge className={"bg-success"}>{jobs[0].days} {"Days "}</Badge>
                                                </li>
                                                <li className="list-inline-item me-3" id="dueDate">
                                                    <i className="bx bx-calendar me-1" /> {jobs[0].create_date}
                                                    <UncontrolledTooltip placement="top" target="dueDate"> Due Date </UncontrolledTooltip>
                                                </li>
                                           
                                            </ul>
                                            <div className="ml-10">
                                                {
                                                teamleader && teamleader.teamleader === id ?

                                                !updateTeamCheck ? <Button disabled onClick={handleCreate} className="btn-sm btn btn-secondary me-1">Update Team</Button>
                                                    : <Button onClick={handleCreate} className="btn-sm btn btn-danger me-1">Update Team</Button>
                                                    
                                                   :<></> }

                                            </div>
                                        </div>



                                    </Card>
                                </Col>


                                <Col xl={4} sm={6}>


                                    <Lottie
                                        animationData={animationteams}
                                        loop
                                        autoplay={true}
                                        renderer="svg" // İsteğe bağlı olarak svg veya canvas olarak belirleyebilirsiniz
                                        style={{ width: '300px', height: '100%', marginTop: "-7px", marginLeft: "-50px" }} // Genişlik ve yükseklik değerlerini buradan belirleyebilirsiniz

                                    />

                                </Col>
                                <Card className="ml-3">
                                    <CardBody className="border-bottom">
                                        <div className="d-flex align-items-center">
                                            <h5 className="mb-0 card-title flex-grow-1">Working Day Lists</h5>
                                            <div className="flex-shrink-0">
                                               {teamleader && teamleader.teamleader === id ?  <Link to="#!" onClick={() => setModal(true)} className="btn btn-primary me-1">Change Shift</Link>  : <></>} 
                                               
                                            </div>
                                        </div>
                                    </CardBody>

                                    <CardBody>
                                        <Col xl={12} className="flex flex-column" > <MyComponent teams={teams} />    </Col>
                                    </CardBody>
                                </Card>












                            </Row>
                        </Container>
                    </div>
                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {"Change Shift"}
                        </ModalHeader>
                        <ModalBody>

                            <Row>
                                <Col className="col-12">


                                    <div className="mb-3">
                                        <Label>Team Friends</Label>
                                        <Input
                                            name="type"
                                            type="select"
                                            className="form-select"
                                            onChange={(e) => {
                                                const user = JSON.parse(e.target.value);
                                                setFriend(prevFriend => ({
                                                    ...prevFriend,
                                                    name: user.fullName,
                                                    id: user._id,
                                                    phone: user.phone,
                                                }));
                                            }}
                                        >
                                            <option value={JSON.stringify({ fullName: false, _id: false })}>Choose Friend</option>
                                            {users.map((user, index) => (
                                                <option key={index} value={JSON.stringify({ fullName: user.fullName, _id: user._id, phone: user.phone })}>{user.fullName}</option>
                                            ))}
                                        </Input>
                                    </div>
                                    <div className="mb-3">

                                        <label>Choose Date</label>
                                        <div className="input-group">
                                            <Flatpickr
                                                className="form-control d-block"
                                                placeholder="dd M,yyyy"
                                                options={{
                                                    altInput: true,
                                                    altFormat: "d-m-Y",
                                                    mode: "multiple",
                                                    dateFormat: "Y-m-d"
                                                }}
                                                onChange={(dates) => {
                                                    const formattedDates = dates.map(date => {
                                                        const d = new Date(date);
                                                        const day = String(d.getDate()).padStart(2, '0');
                                                        const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
                                                        const year = d.getFullYear();
                                                        return `${day}-${month}-${year}`;
                                                    });

                                                    setFriend(prevFriend => ({
                                                        ...prevFriend,
                                                        date: formattedDates
                                                    }));
                                                }}
                                            />
                                        </div>


                                    </div>










                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="text-end">
                                        <Button onClick={updateTeamShift} color="success"
                                            type="submit"
                                            className="save-user"

                                        >
                                            Add Shift
                                        </Button>
                                    </div>
                                </Col>
                            </Row>

                        </ModalBody>
                    </Modal>
                    <Modal isOpen={CreatModal} toggle={toggleCreate} className="h-[500px]" style={{ height: "auto" }} >
                        <ModalHeader toggle={toggleCreate} tag="h4">
                            {"Create Team"}
                        </ModalHeader>
                        <ModalBody>
                            { }
                            <Row>
                                <Col className="col-12">



                                    {users && users.length > 0 ?
                                        <div className="mb-3">
                                            <Label>Team Friends</Label>
                                            <Input
                                                name="type"
                                                type="select"
                                                className="form-select"
                                                onChange={(e) => {
                                                    const user = JSON.parse(e.target.value);
                                                    setNameFriend(user.fullName);
                                                    setFriendID(user.id);
                                                }}
                                            >
                                                <option value={JSON.stringify({ fullName: false, _id: false })}>Choose Friend</option>
                                                {users.map((user, index) => (
                                                    <option key={index} value={JSON.stringify({ fullName: user.fullName, id: user._id })}>{user.fullName}</option>

                                                ))}
                                            </Input>
                                        </div> : <></>}


                                    <div className="mb-3">
                                        <Label>Name Color</Label>




                                        <Input
                                            name="type"
                                            type="select"
                                            className="form-select"

                                            onChange={(e) => {
                                                setNameColor(e.target.value);
                                            }}

                                        >   <option value={false}>Choose Color</option>
                                            <option className="badge-soft-success me-1  h-20 p-5 mt-2" value={"badge-soft-success me-1 rounded-b-sm badge bg-secondary"}>Green</option>
                                            <option className="badge-soft-warning me-1  h-20 p-5 mt-2" value={"badge-soft-warning me-1 rounded-b-sm badge bg-secondary"}>Organge</option>
                                            <option className="badge-soft-danger me-1  h-20 p-5 mt-2" value={"badge-soft-danger me-1 rounded-b-sm badge bg-secondary"}>Red</option>
                                            <option className="badge-soft-primary me-1  h-20 p-5 mt-2" value={"badge-soft-primary me-1 rounded-b-sm badge bg-secondary"}>Blue</option>


                                        </Input>

                                    </div>



                                    <div className="mb-3 h-[360px] ">
                                        <label className="control-label">
                                            Choose Day
                                        </label>
                                        <Select
                                            value={selectedMulti}
                                            isMulti={true}
                                            onChange={(selectedOptions) => {
                                                handleMulti(selectedOptions);
                                            }}
                                            options={dayOptionGroup}
                                            className="select2-selection"
                                        />
                                    </div>







                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="text-end">
                                        <Button onClick={addTeamFunction} color="success"
                                            type="submit"
                                            className="save-user"

                                        >
                                            Add Team
                                        </Button>
                                    </div>
                                </Col>
                            </Row>




                        </ModalBody>
                    </Modal>
                </>
                :
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs title="Thewawy" breadcrumbItem="Teams" />
                        <Row className="flex flex-col items-center justify-start">
                            <Col className="flex flex-col items-center justify-start " >
                            <div className="alert alert-warning fade show" role="alert">You have not added a team yet or you have not applied for a job offer yet.</div>
                                </Col>
                            <Col xl={6} sm={12}>
                                <Lottie
                                    animationData={teamanimation}
                                    loop
                                    autoplay={true}
                                    renderer="svg" // İsteğe bağlı olarak svg veya canvas olarak belirleyebilirsiniz
                                   
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            }



        </React.Fragment>
    );
};



export default Teams;







/*

   const DayComponent = (newUser ) => {
        const daysInMonth = [];
        const currentDate = new Date(); // Geçerli tarihi al
        const currentMonth = currentDate.getMonth(); // Geçerli ayı al
        const daysInMonths = new Date(currentDate.getFullYear(), currentMonth + 1, 0).getDate(); // Ayın gün sayısını al
        for (let i = 1; i <= daysInMonths; i++) {
            const date = new Date(2024, 2, i);
            const dayName = date.toLocaleDateString("gb-GB", { weekday: "long" });
            const formattedDate = `${i.toString().padStart(2, "0")}/03/2024`;

            let name = "Roza"; // Varsayılan kullanıcı adı
            let color = "badge-soft-warning me-1 rounded-b-lg badge bg-secondary"; // Varsayılan renk

            // Eğer newUser boş değilse ve içinde tarihler varsa
            if (newUser && newUser.date && newUser.date.length > 0) {
                // Eğer newUser'ın tarihlerinden biri bu günün tarihiyle eşleşiyorsa
                if (newUser.date.some(userDate => userDate === formattedDate)) {
                    // Kullanıcı adını ve rengini newUser'ın bilgileriyle güncelle
                    name = newUser.name;
                    color = newUser.color;
                }
            }

            // Eğer gün adı Pazar, Pazartesi, Salı veya Çarşamba ise
            if (["Sunday", "Monday", "Tuesday", "Wednesday"].includes(dayName)) {
                // Ve newUser içindeki tarihlerden biri bu günün tarihiyle eşleşiyorsa
                if (newUser && newUser.date && newUser.date.includes(formattedDate)) {
                    // Kullanıcı adını "Sinem" olarak değiştir


                    name = newUser.name;
                    color = newUser.color;
                } else {

                    name = "Sinem";
                    // Renk bilgisini kırmızı yap
                    color = "badge-soft-success me-1 rounded-b-sm badge bg-secondary";
                }
            }
           
            // Güne ait bileşeni oluştur
            daysInMonth.push(
                <Card className=" flex-column p-0 rounded-b-lg  shadow-lg mr-4 mb-3 w-[125px]" key={i}>
               <CardBody className="border-bottom rounded-t-md  p-1 mb-1 bg-gray-700 text-cyan-50">
               <div  className="font-bold " >{dayName}</div>
               </CardBody>
                    
                    <div className="p-2 text-center">{formattedDate}</div>
                    <div  className= { `${color} mt-1 p-1 h-full w-full `} >{name}</div>
                
                </Card>
            );
        }
      
       setCdata(daysInMonth);
        return <Col xl={12} className="flex flex-column" > <Row>{daysInMonth}   </Row> </Col>;
    };











-------------------------------------------------- Aylık Olarak Hesaplar
   function updateTeams(Teams) {
    // Bulunduğumuz ayın tüm günlerini al
    const date = new Date();
    const month = date.getMonth();
    const year = date.getFullYear();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
  
    // Ayın tüm günlerini bir diziye dönüştür
    const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
  
    // Teams dizisini döngüye al
    for (const team of Teams) {
      // Eğer days doluysa, o günleri al ve her birine name ve className özelliklerini ekle
      if (team.days.length > 0) {
        for (const day of days) {
          const dayOfWeek = new Date(year, month, day).getDay();
          const dayName = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][dayOfWeek];
  
          if (team.days.includes(dayName)) {
            const formattedDate = `${day.toString().padStart(2, '0')}-${(month + 1).toString().padStart(2, '0')}-${year}`;
            team.date.push(formattedDate);
          }
        }
      }
    }
  
    return Teams; // Teams dizisini döndür
  }
      
      const daysWithTeams = updateTeams(Teams);
      console.log("Days : ",daysWithTeams);



--------------------------------------- Günleri Güncelleme aynı günlerde aynı kişi varsa silmez ve çalışıyor Başladı

    const addTeamFunction = () => {
        if (nameFriend != false && nameColor != false) {
            const selectedDays = selectedMulti.map(item => item.value);
            const teamIndex = teams.findIndex(team => team.name === nameFriend);

            if (teamIndex !== -1) {
                // Eğer nameFriend isminde bir eleman varsa, bu elemanı güncelle
                const updatedTeams = [...teams];
                updatedTeams[teamIndex] = { id: friendID, name: nameFriend, everytime: true, date: [], days: selectedDays, className: nameColor };
                setTeams(updatedTeams);
                setCreatModal(false);

              //  toast(`${nameFriend}  Friend Updated Team`, { theme: "dark" });
                setUpdateTeamCheck(true);
            } else {
                // Eğer nameFriend isminde bir eleman yoksa, yeni bir eleman ekle
                setTeams([...teams, { id: friendID, name: nameFriend, everytime: true, date: [], days: selectedDays, className: nameColor }]);
                setCreatModal(false);
             //   toast('New Friend Added Team', { theme: "dark" });
                setUpdateTeamCheck(true);
            }
        } else {



            toast.error('Please Select Name and Color', { theme: "dark" });
        }
    };
--------------------------------------- Günleri Güncelleme aynı günlerde aynı kişi varsa silmez ve çalışıyor Bitti



*/

