import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Link } from "react-router-dom"

//Import Images
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import { PROFIL_IMG } from "ApiConfig";
function CardTop(props) {
  const [settingsMenu, setSettingsMenu] = useState(false)
  //Setting Menu


  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
           
                <Col lg="4">
                  <div className="d-flex">
                    <div className="me-3">
                      <img
                        src={`${PROFIL_IMG}${props.info.profilimage.picture_path}`}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <p className="mb-2">Welcome to Thewawy Dashboard</p>
                        <h5 className="mb-1">{props.info.fullName}</h5>
                        <span className="badge badge-soft-success">Approved</span>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg="4" className="align-self-center">
                  <div className="text-lg-center mt-4 mt-lg-0">
                    <Row>
                      <Col xs="4">
                
                      </Col>
                      <Col xs="4">
            
                      </Col>
                      <Col xs="4">
                  
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col lg="4" className="d-none d-lg-block">
                  <div className="clearfix mt-6 mt-lg-0 float-end">
                 
                            <Link to="/CreateOffer" className="btn btn-danger mt-3"><i className="bx bx-plus align-middle"></i> Add New Offer</Link>
                       
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CardTop
