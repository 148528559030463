import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Container , Col,
  Row
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { connect } from 'react-redux';


import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import InviteFriends from "./InviteFriends";
import Comments from "components/Dashboard/Comments";
import { Dna } from 'react-loader-spinner'
import LastJobs from "./LastJobs";
import CardTop from "./CardTop";
import UserList from "./UserList";



const Dashboard = () => {

  //meta title
  document.title = "Dashboard | The Wawy - Client Dashboard ";


  const dispatch = useDispatch();
  const [dashboardData , setdashboardData] = useState()
  const [usersData , setUsersData] = useState()
  useEffect(() => {
    dispatch({ type: 'API_DASHBOARD_GET' })
    
  },[])
  const dashboard = useSelector(state => state.Dashboard.action.datam?.ServerDashboard)
  const users = useSelector(state => state.Dashboard.action?.datam?.users)
  useEffect(() => {
    if (users) {
     setdashboardData(dashboard);
     setUsersData(users);
    }
  }, [users]);







  return (
    <React.Fragment>
    {!dashboardData ?
      <div className="page-content">
        <Container fluid>
        

          <Row style={{ height: "100vh" }} >
            <Col lg="12">

            </Col>
            <Row>
              <Col lg="4"></Col>
              <Col lg="4" className="text-center">
                <Dna
                  visible={true}
                  height="160"
                  width="160"
                  ariaLabel="dna-loading"
                  wrapperStyle={{}}
                  wrapperClass="dna-wrapper"
                />
              </Col>
              <Col lg="4"></Col>


            </Row>
            <Col lg="12">

            </Col>
          </Row>

        </Container>
      </div>
      :
      <div className="page-content">
        <Container fluid>

          <h4>Dashboard</h4>

          <CardTop info={dashboardData}  />
          <Row >
          
            <Col xl="12">
              <Row>
               
               <UserList  />

              </Row>
            </Col>
          </Row>




        </Container>
      </div>

    }
  </React.Fragment>
  );
};




export default connect()((withTranslation())(Dashboard))





