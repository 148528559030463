import { takeEvery, put, call } from "redux-saga/effects"
import axios from "axios";
import {jwtDecode} from "jwt-decode"
import {
   JOBS_FRIEND_GET,
   JOBS_FRIEND_CHECK_GET
  
        } from "./actionTypes"

import { Jobs_friend_SET, Jobs_friend_check_SET } from "./actions";







function* FiendJobs ({FriendData}) {

  

   async function API() {
       var client = jwtDecode(localStorage.getItem("authUser"))
       var id = client.client_id
 
       const response = await axios
          .post(`${API_SERVICE_UPDATE}JobsFriend`, {
            token: JSON.parse(localStorage.getItem("authUser")), 
            client_id: id,
            job:FriendData.job,
            friend_id:FriendData.friend_id
          })
          .then(response => response)
          .catch(err => {
             console.log("Bitmedi")
             // LoginButtonStatus(dispatch,"a")
          })
 
 
       return response
    }
 
    // Login İşleminde Sunucudan Dönen Yanıt
    const datam = yield call(API)

    yield put(Jobs_friend_SET(datam)) 

}

function* FiendCheckJobs (job) {



   async function API() {
       var client = jwtDecode(localStorage.getItem("authUser"))
       var id = client.client_id
 
       const response = await axios
          .post(`${API_SERVICE_QUERY}JobsFriendCheck`, {
            token: JSON.parse(localStorage.getItem("authUser")), 
            job_id:job.job_id
          })
          .then(response => response)
          .catch(err => {
             console.log("Bitmedi")
             // LoginButtonStatus(dispatch,"a")
          })
 
 
       return response
    }
 
    // Login İşleminde Sunucudan Dönen Yanıt
    const datam = yield call(API)


    yield put(Jobs_friend_check_SET(datam)) 

}

function* JobsFriend() {


    yield takeEvery(JOBS_FRIEND_GET, FiendJobs)
    yield takeEvery(JOBS_FRIEND_CHECK_GET, FiendCheckJobs)
 }
 
 export default JobsFriend


 