import { 
    JOBS_VIEW_SET
        } from "./actionTypes"


const initialState = {
    error:"",
    status: false,
}




 const JobsVivew = (state = initialState,action) => {

  switch (action.type) {

        case JOBS_VIEW_SET:
          return {
            ...state,
            action
          };
   
      default:
        return { ...state };
  }


}


export default JobsVivew        