import { takeEvery, put, call } from "redux-saga/effects"
import axios from "axios";
import { API_SERVICE_QUERY, API_SERVICE,API_SERVICE_UPDATE } from "ApiConfig";
import {jwtDecode} from "jwt-decode"
import { 

    OFFER_TEAM_LEADER_GET,
    
        } from "./actionTypes"

import { Offer_TeamLeader_SET } from "./actions";

import { notify } from "store/notification/action";





function* Offer_TeamLeader_GET_Saga (Offer_id) {
   
       async function API() {
           var client = jwtDecode(localStorage.getItem("authUser"))
           var id = client.client_id
     
           const response = await axios
              .post(`${API_SERVICE_QUERY}OfferTeamLeaderGet`, {
                offerId:Offer_id.id,
                 token: JSON.parse(localStorage.getItem("authUser"))
              })
              .then(response => response)
              .catch(err => {
                 console.log("Bitmedi")
                 // LoginButtonStatus(dispatch,"a")
              })
     
     
           return response.data
        }
     
        // Login İşleminde Sunucudan Dönen Yanıt
        const datam = yield call(API)
     

        yield put(Offer_TeamLeader_SET(datam)) 
   }




function* TeamLeader() {


    yield takeEvery(OFFER_TEAM_LEADER_GET, Offer_TeamLeader_GET_Saga)
    
 }

 
 
 export default TeamLeader