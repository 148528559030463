import { 
  JOBS_APPLY_GET,
  JOBS_APPLY_SET 
      } from "./actionTypes"




export const Jobs_apply_GET = (ApplyData) => ({
  type:JOBS_APPLY_GET,
  ApplyData 
})

export const Jobs_apply_SET = ApplyData => ({
  type:JOBS_APPLY_SET,
  ApplyData,
})