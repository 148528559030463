import React, { useState, useEffect} from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col,Container } from "reactstrap";
import SimpleBar from "simplebar-react";
import { useSelector, useDispatch } from "react-redux";
import { Inform_API } from "store/inform/actions";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";


import leader from "../../assets/lottie/leader3.json"; // Lottie JSON dosyasının yolu
import accept from "../../assets/lottie/accept4.json"; // Lottie JSON dosyasının yolu
import announcement from "../../assets/lottie/new3.json"; // Lottie JSON dosyasının yolu
import Lottie from "lottie-react";



//i18n
import { withTranslation } from "react-i18next";

const Notification = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

const dispatch = useDispatch();

  useEffect(() => { 

    dispatch(Inform_API());

  }, [dispatch]);



  const inform = useSelector((state) => state.InformReducer.action?.informList?.inform  );

  return (
    <React.Fragment>
              <div className="page-content">
                        <Container fluid>
                            {/* Render Breadcrumb */}
                            <Breadcrumbs title="Thewawy" breadcrumbItem="Notification" />

                            <Row className="d-flex ">

          {inform ? [...inform].reverse().map((item, key) => {
 
  return (
           
    item.type === "ClientApplicationApproval" ?    
    <Link to="" className="text-reset notification-item">
                                                                     <div className="d-flex">
                                                                       <div className="avatar-xs me-3">
                                                                         <span className="avatar-title  border-stone-950 border-4 bg-white   rounded-circle font-size-16">
                                                                         <Lottie
                                                   animationData={accept}
                                                   loop
                                                   autoplay={true}
                                                   renderer="svg"
                                                   style={{ width: '50px', marginTop:"4px" }}
                                               />
                                                                         </span>
                                                                       </div>
                                                                       <div className="flex-grow-1">
                                                                         <h6 className="mt-0 mb-1">
                                                                           {props.t("New Offer application")}
                                                                         </h6>
                                                                         <div className="font-size-12 text-muted">
                                                                           <p className="mb-1">
                                                                             {item.partnerName} applied to your offer named {item.offerTitle} 
                                                                           </p>
                                                                           <p className="mb-0">
                                                                             <i className="mdi mdi-clock-outline" />{" "}
                                                                             {item.time}
                                                                           </p>
                                                                         </div>
                                                                       </div>
                                                                     </div>
                                                                     </Link>
    
                                            : item.type === "TeamLeader" ?   
                                                                          <Link to="" className="text-reset notification-item">
                                                                          <div className="d-flex ">
                                                                            <div className="avatar-xs me-3">
                                                                              <span className="avatar-title border-stone-950 border-4 bg-white  rounded-circle font-size-16">
                                                                                  <Lottie
                                                                                  animationData={leader}
                                                                                  loop
                                                                                  autoplay={true}
                                                                                  renderer="svg"
                                                                                  style={{ width: '50px', marginTop:"4px" }}
                                                                              />
                                                                              </span>
                                                                            </div>
                                                                            <div className="flex-grow-1">
                                                                              <h6 className="mt-0 mb-1">
                                                                                {"Team Leader"}
                                                                              </h6>
                                                                              <div className="font-size-12 text-muted">
                                                                                <p className="mb-1">
                                                                                {"Congratulations, "}{item.title}
                                                                                </p>
                                                                                <p className="mb-0">
                                                                                  <i className="mdi mdi-clock-outline" />{" "}
                                                                                  {item.time}{" "}
                                                                                </p>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                          </Link> 
                                                                          
                                                                          :  item.type === "offer" ?    
                                                                     
                                                                                                      <Link to="" className="text-reset notification-item">
                                                                                                      <div className="d-flex ">
                                                                                                        <div className="avatar-xs me-3">
                                                                                                          <span className="avatar-title border-stone-950 border-4 bg-white  rounded-circle font-size-16">
                                                                                                              <Lottie
                                                                                                              animationData={announcement}
                                                                                                              loop
                                                                                                              autoplay={true}
                                                                                                              renderer="svg"
                                                                                                              style={{ width: '50px', marginTop:"4px" }}
                                                                                                          />
                                                                                                          </span>
                                                                                                        </div>
                                                                                                        <div className="flex-grow-1">
                                                                                                          <h6 className="mt-0 mb-1">
                                                                                                            {"New Offer"}
                                                                                                          </h6>
                                                                                                          <div className="font-size-12 text-muted">
                                                                                                            <p className="mb-1">
                                                                                                            {item?.job_title  }</p>
                                                                                                            <p className="mb-1">
                                                                                                            £{item?.fee.fee } fee {" / "}{item?.fee?.type }{" / "}{item?.fee?.hour }</p>
                                                                                                            <p className="mb-0">
                                                                                                              <i className="mdi mdi-clock-outline" />{" "}
                                                                                                              {item.time}{" "}
                                                                                                            </p>
                                                                                                          </div>
                                                                                                        </div>
                                                                                                      </div>
                                                                                                      </Link> 
                                                                                                    
                                                                                                      
                                                                                                    : <></>
                                                                              


        );




      }) : null}
     
             
</Row>
</Container>
</div>
      
    </React.Fragment>
  );
};

export default withTranslation()(Notification);

Notification.propTypes = {
  t: PropTypes.any
};