import { 
  JOBS_VIEW_GET,
  JOBS_VIEW_SET 
      } from "./actionTypes"



export const Jobs_view_GET = id => ({
  type:JOBS_VIEW_GET,
  id,
})

export const Jobs_view_SET = JobsData => ({
  type:JOBS_VIEW_SET,
  JobsData,
})

