import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Input,
    FormGroup,
    Label,
    Button,
    InputGroup
} from "reactstrap";
import { Dna } from 'react-loader-spinner'
// Import Editor
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";



//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { Offer_Update_GET } from "store/actions";

import { useLocation } from 'react-router-dom';
import { Offer_Single_GET } from "store/offer/actions";

import { useDispatch, useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
const EditOffer = () => {

    const dispatch = useDispatch()
    //meta title
    document.title = "Edit Offer  | The Wawy - Client Dashboard ";
    const location = useLocation();
    const _id = location.pathname.split('/').pop();
    const [loading, setLoading] = useState(true);

    const [ApiData, setApiData] = useState("default");

    useEffect(() => {
        dispatch(Offer_Single_GET(_id));

    }, [dispatch, _id]);

    const myReduxData = useSelector(state => state.OfferReducers.action
    );



    useEffect(() => {




        if (myReduxData) {
            setWorktype(myReduxData.OfferData.offers[0].worktypeArray)
            setWorktime(myReduxData.OfferData.offers[0].worktimeArray)
            setHours(myReduxData.OfferData.offers[0].hoursArray)
            setDays(myReduxData.OfferData.offers[0].daysArray)
            setFuture(myReduxData.OfferData.offers[0].requirements)
            setRisk(myReduxData.OfferData.offers[0].priority)
            setprice(myReduxData.OfferData.offers[0].fee.fee)
            setoffername(myReduxData.OfferData.offers[0].job_title)
            setApiData(myReduxData.OfferData.offers[0].job_about)
            setLoading(false); // Veri başarıyla geldiyse loading durumunu false yap


        }
    }, [myReduxData])




    // ****************WORK TYPE İŞLEMLERİ BAŞLAR********************* 
    const [worktypeName, seTworktypeName] = useState()
    const [worktype, setWorktype] = useState();

    const handleSetWorkType = (id) => {

        const updatedWorktype = worktype.map(item => {
            if (item.id === id) {
                seTworktypeName(item.name);
                return { ...item, checked: true };
            } else {
                return { ...item, checked: false };
            }
        });
        setWorktype(updatedWorktype);
    };


    // ****************WORK TIME İŞLEMLERİ BAŞLAR********************* 
    const [worktimeName, seTworktimeName] = useState()
    const [worktime, setWorktime] = useState();

    const handleSetWorkTime = (id) => {
        const updatedWorktime = worktime.map(item => {
            if (item.id === id) {
                seTworktimeName(item.name)
                return { ...item, checked: true };
            } else {
                return { ...item, checked: false };
            }
        });
        setWorktime(updatedWorktime);
    };



    // ****************HOURS İŞLEMLERİ BAŞLAR********************* 
    const [hoursName, sethoursName] = useState()
    const [hours, setHours] = useState()

    const handleSetHour = (id) => {
        const updatedHours = hours.map(item => {
            if (item.id === id) {
                sethoursName(item.name)
                return { ...item, checked: true };
            } else {
                return { ...item, checked: false };
            }
        });
        setHours(updatedHours);
    };




    // ****************DAYS İŞLEMLERİ BAŞLAR********************* 
    const [days, setDays] = useState()

    const handleSetDay = id => {
        const updatedDays = days.map(item => {
            if (item.id === id) {
                return { ...item, checked: !item.checked };
            }
            return item;
        });
        setDays(updatedDays);
    };



    // ****************DENEYİM İŞLEMLERİ BAŞLAR********************* 
    const [futureName, setFutureName] = useState("");
    const [future, setFuture] = useState([]);

    // ÖZel deneyim ekler  
    const handleAddFuture = () => {
        const maxId = future.reduce((max, obj) => (obj.id > max ? obj.id : max), 0);
        const num = maxId + 1;
        const data = future.some(obj => obj.name === futureName) ? [...future] : [...future, { id: num, name: futureName }];

        setFuture(data)
        setFutureName(''); // input elementinin değerini sıfırla
    }

    // Eklenen özel deneyimi siler
    const handleRemoveFuture = (id) => {
        const updatedFuture = future.filter(obj => obj.id !== id);
        setFuture(updatedFuture);

    }



    // ****************RISK İŞLEMLERİ BAŞLAR********************* 

    const [risk, setRisk] = useState();

    const handleSeRisk = (id) => {
        const updatedRisk = risk.map(item => {
            if (item.id === id) {
                return { ...item, checked: true };
            } else {
                return { ...item, checked: false };
            }
        });
        setRisk(updatedRisk);
    };











    // HTML'den ContentState oluştur
    const contentBlocks = convertFromHTML(ApiData);
    const contentState = ContentState.createFromBlockArray(contentBlocks);

    // ContentState kullanarak EditorState oluştur
    const defaultEditorState = EditorState.createWithContent(contentState);

    const [editorState, setEditorState] = useState(defaultEditorState);
    const [lastdescription, setLastDescription] = useState();

    const handleContentChange = (state) => {
        setEditorState(state);

    };

    useEffect(() => {
        const contentState = editorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        setLastDescription(draftToHtml(rawContentState));
    }, [editorState]);


    /* 
     Eğer ApiData değerindeki değişiklikleri yansıtmak istiyorsanız, convertFromHTML fonksiyonunu her ApiData güncellendiğinde tekrar çağırmalısınız.
     Örneğin, ApiData değiştiğinde useEffect içinde convertFromHTML fonksiyonunu tekrar çağırabilirsiniz:
    */
    useEffect(() => {
        const contentBlocks = convertFromHTML(ApiData);
        const contentState = ContentState.createFromBlockArray(contentBlocks);
        const updatedEditorState = EditorState.createWithContent(contentState);
        setEditorState(updatedEditorState);
    }, [ApiData]);




    const [offername, setoffername] = useState("");
    const [price, setprice] = useState("");


    var total = days ? days.filter(day => day.checked === true).length : <></>;




    const handleSubmit = () => {
        setToastStatu(true)
        var data = {
            offer_id: _id,
            offername: offername,
            offerdescription: lastdescription,
            price: price,
            worktypeName: worktypeName,
            worktimeName: worktimeName,
            hoursName: hoursName,
            worktypeArray: worktype,
            worktimeArray: worktime,
            hoursArray: hours,
            futureArray: future,
            daysArray: days,
            risk: risk,
            days: total

        }
       
        dispatch(Offer_Update_GET(data))

        setTimeout(() => { setToastStatu(false) }, 5000);

    }

    const [toastStatus, setToastStatu] = useState(false)
    const notify = useSelector(state => state.NotifyReducer.action);

useEffect(() => { 

    if (notify) {

        const shouldSucceed = notify.payload.status; // veya false

        const simulateFetchData = (shouldSucceed) => {

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    if (shouldSucceed) {
                        resolve("Data successfully fetched");

                    } else {
                        reject(new Error("Error when fetching data"));

                    }
                }, 1000); // 2 saniye sonra resolve veya reject
            });

        };


        ;

        toast.promise(simulateFetchData(shouldSucceed), {
            loading: 'Loading',
            success: (data) => {
                if(toastStatus){ 

                    setTimeout(() => {
                        setToastStatu(false);
                      //navigate('/MyOffers'); // navigate to new page 
                      window.location.href = "/MyOffers";
                    }, 3000);
                   }
                // Başlık ve mesajı kullanarak bir string oluşturabilir veya başka bir şekilde işleyebilirsiniz.
                return (<>
                    <div><strong>{notify.payload.title}</strong> <br />{notify.payload.message}</div>
                </>);
            },
            error: (data) => {
                // Başlık ve mesajı kullanarak bir string oluşturabilir veya başka bir şekilde işleyebilirsiniz.
                return (<>
                    <div><strong>{notify.payload.title}</strong> <br />{notify.payload.message}</div>
                </>);
            },
        }, {

            success: {
                duration: 3000,

            },
        });

    }

}, [notify]);




    if (!loading) {

        return (
            <> {toastStatus ? <Toaster position="top-right" reverseOrder={false} /> : <></>}
                <div className="page-content">

                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title={"Offer"} breadcrumbItem={"Offer Edit"} />

                        <Row>
                            <Col lg="12">
                                <Card>


                                    <div>
                                    </div>

                                    <CardBody>

                                        <CardTitle className="mb-4">{" "}</CardTitle>
                                        <form className="outer-repeater">
                                            <div data-repeater-list="outer-group" className="outer">
                                                <div data-repeater-item className="outer">
                                                    <FormGroup className="mb-4" row>
                                                        <Label
                                                            htmlFor="taskname"
                                                            className="col-form-label col-lg-2"
                                                        >
                                                            Offer Name
                                                        </Label>
                                                        <Col lg="10">
                                                            <Input
                                                                id="taskname"
                                                                name="taskname"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Offer Name..."
                                                                value={offername}
                                                                onChange={(e) => setoffername(e.target.value)}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup className="mb-4" row>
                                                        <Label className="col-form-label col-lg-2">
                                                            Offer Description
                                                        </Label>
                                                        <Col lg="10">
                                                            <Editor
                                                                toolbarClassName="toolbarClassName"
                                                                wrapperClassName="wrapperClassName"
                                                                editorClassName="editorClassName"
                                                                placeholder="Place Your Content Here..."
                                                                editorState={editorState}
                                                                onEditorStateChange={(state) => handleContentChange(state)}

                                                            />
                                                        </Col>
                                                    </FormGroup>





                                                    <FormGroup className="mb-4" row>
                                                        <label
                                                            htmlFor="taskbudget"
                                                            className="col-form-label col-lg-2"
                                                        >
                                                            Price (hour)
                                                        </label>
                                                        <div className="col-lg-10">
                                                            <InputGroup>
                                                                <div className="input-group-text">£</div>
                                                                <input type="text" value={price} className="form-control" id="inlineFormInputGroupUsername" placeholder="0" onChange={(e) => setprice(e.target.value)} />
                                                            </InputGroup>
                                                        </div>
                                                    </FormGroup>


                                                    <FormGroup className="mb-4" row>
                                                        <label
                                                            htmlFor="taskbudget"
                                                            className="col-form-label col-lg-2 "
                                                        >


                                                         
                                                        </label>
                                                        <div className="col-lg-10">

                                                            <Row >


                                                                <Col sm="12" className="ml-0 border-0 via-black mb-1 mt-4 " >
                                                                    <Col sm="12" className=" text-sm font-medium col-form-label border-bottom border-2 border-b-sky-600  bg-rose-500  pl-0 ml-1 mb-4"><span className=" bg-rose-500  text-cyan-50 pl-2 pr-2 pt-2 pb-[9px]">WORKER TYPE </span></Col>
                                                                    <Row>
                                                                        {worktype ?
                                                                            worktype.map((item, key) => (
                                                                                <Col key={key} xl="2" sm="4">
                                                                                    <div className="mb-3">
                                                                                        <label className="card-radio-label mb-2">
                                                                                            <input
                                                                                                type="radio"
                                                                                                name={item.name}
                                                                                                id="buycurrencyoption1"
                                                                                                className="card-radio-input "
                                                                                                onChange={(e) => { handleSetWorkType(item.id) }}
                                                                                                // defaultChecked
                                                                                                checked={item.checked}
                                                                                                readOnly
                                                                                            />

                                                                                            <div className="card-radio card-radio-worker">
                                                                                                <div className="p-2">
                                                                                                    <img src={item.images} className={`w-[36px] text-warning align-middle  ${item.name == "Housekeeping" ? 'me-1 -ml-4' : 'me-2'}`} />

                                                                                                    {" "}
                                                                                                    <span>{item.name}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </label>


                                                                                    </div>
                                                                                </Col>

                                                                            )) : <></>}

                                                                    </Row>
                                                                </Col>

                                                                <Col sm="12" className="ml-0  border-0 via-black mb-4 mt-4 " >
                                                                    <div className="">

                                                                        <Col sm="12" className=" text-sm font-medium col-form-label border-bottom border-2 border-b-sky-600  bg-lime-500  pl-0 ml-1 mb-4"><span className=" bg-lime-500 text-cyan-50 pl-2 pr-2 pt-2 pb-[9px]">WORK TIME</span></Col>

                                                                        <Row>


                                                                            {worktime ? worktime.map((item, key) => (
                                                                                <Col key={key} xl="2" sm="4">
                                                                                    <div className="mb-3">
                                                                                        <label className="card-radio-label mb-2">
                                                                                            <input
                                                                                                type="radio"
                                                                                                name={item.name}
                                                                                                id="buycurrencyoption2"
                                                                                                className="card-radio-input "
                                                                                                onChange={(e) => { handleSetWorkTime(item.id) }}
                                                                                                checked={item.checked}
                                                                                                readOnly
                                                                                            />

                                                                                            <div className="card-radio card-radio-week">
                                                                                                <div className="p-2">
                                                                                                    <img src={item.images} className=" w-[24px] text-warning align-middle me-2" />
                                                                                                    {" "}
                                                                                                    <span>{item.name}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </label>


                                                                                    </div>
                                                                                </Col>
                                                                            )) : <></>}




                                                                        </Row>
                                                                    </div>
                                                                </Col>


                                                                <Col sm="12" className="ml-0  border-0 via-black  mb-4  " >


                                                                    <Col sm="12" className=" text-sm font-medium col-form-label border-bottom border-2 border-b-sky-600 bg-amber-300  pl-0 ml-1 mb-4"><span className=" bg-amber-300 text-cyan-50 pl-2 pr-2 pt-2 pb-[9px]">WORK HOUR</span></Col>

                                                                    <Row>
                                                                        {hours ? hours.map((item, key) => (

                                                                            <Col key={key} xl="2" sm="4">
                                                                                <div className="mb-3 text-center ">
                                                                                    <label className="card-radio-label mb-2">
                                                                                        <input
                                                                                            type="radio"
                                                                                            name={item.name}
                                                                                            id="buycurrencyoption3"
                                                                                            className="card-radio-input"
                                                                                            onChange={(e) => { handleSetHour(item.id) }}
                                                                                            checked={item.checked}
                                                                                            readOnly
                                                                                        />

                                                                                        <div className="card-radio card-radio-time">
                                                                                            <div className="">
                                                                                                <img src={item.images} className=" w-[24px] text-warning align-middle me-2" />
                                                                                                {" "}
                                                                                                <span className="">{item.name}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                            </Col>
                                                                        )) : <></>}
                                                                    </Row>

                                                                </Col>



                                                                <Col sm="12" className="ml-0  border-0 via-black  " >


                                                                    <Col sm="12" className=" text-sm font-medium col-form-label border-bottom border-2 border-b-sky-600  bg-sky-400 pl-0 ml-1 mb-4"><span className=" bg-sky-400 text-cyan-50 pl-2 pr-2 pt-2 pb-[9px]">WORK DAY</span></Col>
                                                                    <Row>
                                                                        {days ?
                                                                            days.map((item, key) => (
                                                                                <Col key={key} xl="2" sm="4" >
                                                                                    <div className="mb-3 text-center ">
                                                                                        <label className="card-radio-label mb-2 ">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                name={item.name}
                                                                                                className="card-radio-input"
                                                                                                onChange={(e) => handleSetDay(item.id)}
                                                                                                checked={item.checked}
                                                                                            />

                                                                                            <div className="card-radio card-radio-my">
                                                                                                <div className="">
                                                                                                    <img src={item.images} className=" w-[24px] text-warning align-middle me-2" />
                                                                                                    {" "}
                                                                                                    <span className=""> {item.name} </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </label>


                                                                                    </div>
                                                                                </Col>
                                                                            ))

                                                                            : <></>}
                                                                    </Row>

                                                                </Col>


                                                                <Col sm="12" className="ml-0  border-0 via-black  " >


                                                                    <Col sm="12" className=" text-sm font-medium col-form-label border-bottom border-2 border-b-teal-500  bg-teal-500 pl-0 ml-1 mb-4"><span className=" bg-teal-500 text-cyan-50 pl-2 pr-2 pt-2 pb-[9px]">PRIORITY LEVEL</span></Col>
                                                                    <Row>
                                                                        {risk ?
                                                                            risk.map((item, key) => (
                                                                                <Col key={key} xl="2" sm="4" >
                                                                                    <div className="mb-3 text-center ">
                                                                                        <label className="card-radio-label mb-2 ">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                name={item.name}
                                                                                                id="buycurrencyoption4"
                                                                                                className="card-radio-input"
                                                                                                onChange={() => handleSeRisk(item.id)}
                                                                                                checked={item.checked}
                                                                                                readOnly

                                                                                            />

                                                                                            <div className="card-radio card-radio-risk">
                                                                                                <div className="">
                                                                                                    <img src={item.images} className=" w-[52px] text-warning align-middle me-2" />
                                                                                                    {" "}
                                                                                                    <span className=""> {item.name} </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </label>


                                                                                    </div>
                                                                                </Col>
                                                                            ))
                                                                            : <></>}
                                                                    </Row>

                                                                </Col>



                                                                <Col sm="12" className="ml-0  border-0 via-black  mt-4 " >


                                                                    <Col sm="12" className=" text-sm font-medium col-form-label border-bottom border-2 border-b-sky-600  bg-red-500 pl-0 ml-1 mb-4"><span className=" bg-red-500 text-cyan-50 pl-2 pr-2 pt-2 pb-[9px]">EXPERIENCES</span></Col>
                                                                    <Row>
                                                                        <Col md="12" className="mt-2 mb-4">
                                                                            <span className=" font-bold">
                                                                                You can include the specific experiences and skills you require from applicants who will apply to this job offer.
                                                                            </span>
                                                                        </Col>

                                                                        <div className="mb-3 row align-items-center" >
                                                                            <Col md="6">
                                                                                <input
                                                                                    type="text"
                                                                                    name="future"
                                                                                    className="inner form-control"
                                                                                    placeholder="Enter Experience..."
                                                                                    value={futureName}
                                                                                    onChange={(e) => setFutureName(e.target.value)}
                                                                                />
                                                                            </Col>

                                                                            <Col md="2">
                                                                                <div className="mt-2 mt-md-0 d-grid">
                                                                                    <Button
                                                                                        color="success"
                                                                                        className="inner"
                                                                                        onClick={() => { handleAddFuture() }}
                                                                                        block
                                                                                    >
                                                                                        Add Experience
                                                                                    </Button>
                                                                                </div>
                                                                            </Col>

                                                                            <Col md="12" className="mt-4" >
                                                                                <Row>
                                                                                    {(future || []).map((item, key) => (
                                                                                        <Col md="3" key={key} className="mt-2" > <span className="px-3 py-2 rounded bg-light bg-opacity-50 d-block mb-2">{item.name}<span onClick={() => handleRemoveFuture(item.id)} className="badge text-bg-danger float-end bg-opacity-100"><i className="bx bx-trash text-xs"></i></span></span> </Col>
                                                                                    ))}


                                                                                </Row>

                                                                            </Col>
                                                                        </div>
                                                                    </Row>
                                                                </Col>

                                                            </Row>


                                                        </div>





                                                    </FormGroup>
                                                </div>
                                            </div>

                                        </form>

                                        <Row className="justify-content-end">
                                            <Col lg="10" >
                                           
                                                {toastStatus ? <>           
                    <button className="w-full h-[80px] px-6 text-indigo-100 border rounded-md btn btn-outline-light mb-6">     <Dna
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="dna-loading"
                        wrapperStyle={{}}
                        wrapperClass="dna-wrapper"
                      /></button>

                      </> :
                        <button onClick={() => handleSubmit()} className="w-full h-12 px-6 text-indigo-100 border rounded-md  bg-indigo-500 hover:bg-indigo-600 mb-6">Update Offer Now</button>
                      }
                                           
                                           
                                            </Col>

                                        </Row>

                                        <Row className="justify-content-end">
                                            <Col lg="10">

                                            </Col>

                                        </Row>
                                    </CardBody>

                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )


    }
    else {
        return (<div className="page-content">
            <Container fluid>


                <Row style={{ height: "100vh" }} >
                    <Col lg="12">

                    </Col>
                    <Row>
                        <Col lg="4"></Col>
                        <Col lg="4" className="text-center">
                            <Dna
                                visible={true}
                                height="160"
                                width="160"
                                ariaLabel="dna-loading"
                                wrapperStyle={{}}
                                wrapperClass="dna-wrapper"
                            />
                        </Col>
                        <Col lg="4"></Col>


                    </Row>
                    <Col lg="12">

                    </Col>
                </Row>

            </Container>
        </div>)
    }



}






export default EditOffer;


/*

                       <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2"
                          >
                            Budget
                          </label>
                          <div className="col-lg-10">
                            <Input
                              id="taskbudget"
                              name="taskbudget"
                              type="text"
                              placeholder="Enter Task Budget..."
                              className="form-control"
                            />
                          </div>
                        </FormGroup>

*/





/*

const [editorState, setEditorState] = useState( () => EditorState.createEmpty());
const [convertedContent, setConvertedContent] = useState(null);


useEffect(() => {
  let html = convertToHTML(editorState.getCurrentContent());
  setConvertedContent(html);
}, [editorState]);


     <div dangerouslySetInnerHTML={createMarkup(convertedContent)}></div>



*/