import { 
  JOBS_TEAM_GET,
  JOBS_TEAM_SET 
      } from "./actionTypes"



export const Jobs_team_GET = () => ({
  type:JOBS_TEAM_GET,
 
})

export const Jobs_team_SET = JobsData => ({
  type:JOBS_TEAM_SET,
  JobsData,
})

