import { 
  
    JOBS_BOOKMARKS_SET 
        } from "./actionTypes"


const initialState = {
    error:"",
    status: false,
}


const JobsReducers = (state = initialState,action) => {

    switch (action.type) {
    
          case JOBS_BOOKMARKS_SET:
            return {
              ...state,
              action
            };
        default:
          return { ...state };
    }


}

export default JobsReducers
        