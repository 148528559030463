//import PropTypes from 'prop-types'
import React from "react";

import { connect } from "react-redux";
import {
    Row,
    Col,
    Card,
    CardBody,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    ButtonDropdown,
    UncontrolledDropdown

  } from "reactstrap"
//i18n
import { withTranslation } from "react-i18next";
import { DOCUMNET_FILES } from "ApiConfig";

const CertificatesCard =({info}) => {



 
  
    return (
        <Card>
        <CardBody>
          <h4 className="card-title">Certificates</h4>

          <Row>

          { info.certificates.map((cert, key) => ( 
  <React.Fragment key={key}>
    {cert.remove!=1 ? 
      <Col lg="4">
        <div className="border p-3 rounded mt-4">
          <div className="d-flex align-items-center mb-3">
            {cert.file_status==1 ? 
              <div className="avatar-xs me-3">
                <span className="avatar-title rounded-circle bg-success bg-soft text-success font-size-18">
                  <i className="mdi mdi-file-pdf"></i>
                </span>
              </div>
            : 
              <div className="avatar-xs me-3">
                <span className="avatar-title rounded-circle bg-danger bg-soft text-danger font-size-18">
                  <i className="mdi mdi-file-pdf"></i>
                </span>
              </div>
            }
            <h5 className="font-size-14 mb-0">{cert.certificate_name}</h5>
          </div>
          <Row>
            <div className="col-lg-6">
              <div className="text-muted mt-3">
                <h5> {cert.file_status==1 ? <a href={`${DOCUMNET_FILES}${cert.file_path}`}><span className='badge-soft-success me-1 badge bg-secondary'>Download </span> </a> : <span className='badge-soft-danger me-1 badge bg-secondary'>Private</span> }</h5>
              </div>
            </div>
            <div className="col-lg-6 align-self-end">
              <div className="float-end mt-3">
              </div>
            </div>
          </Row>
        </div>
      </Col>
    : null }
  </React.Fragment>
))}

           
            
          </Row>
        </CardBody>
      </Card>
    )
 
}




const mapStateToProps = (state) => {
  return {
    data: state.Dashboard.action.datam,

  };
};


export default connect(mapStateToProps )((withTranslation())(CertificatesCard))


