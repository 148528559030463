import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
  InputGroup
} from "reactstrap";

// Import Editor
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useNavigate } from "react-router-dom";
import { Dna } from 'react-loader-spinner'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"


import { useDispatch, useSelector } from "react-redux"

import daily from "../../assets/images/create/daily.png"
import weakly from "../../assets/images/create/weakly.png"
import monthly from "../../assets/images/create/monthly.png"
import livein from "../../assets/images/create/3.png"

import time3 from "../../assets/images/create/time/2.png"
import time4 from "../../assets/images/create/time/3.png"
import imgnurse from "../../assets/images/create/worker/sec/nurse.png"
import imgunnurse from "../../assets/images/create/worker/sec/nursered.png"
import imghealthcare from "../../assets/images/create/worker/sec/social-worker.png"
import imghousekeeping from "../../assets/images/create/worker/sec/maid.png"
import imgnanny from "../../assets/images/create/worker/sec/nanny2.png"
import imgbabysitter from "../../assets/images/create/worker/sec/babysitter2.png"
//Risk image
import imglow from "../../assets/images/create/risk/low.png"
import imgmedium from "../../assets/images/create/risk/medium.png"
import imghigh from "../../assets/images/create/risk/high.png"

import allday from "../../assets/images/create/days/3.png"
import { Offer_Create_GET } from "store/actions";
import { use } from "i18next";
import toast, { Toaster } from 'react-hot-toast'



const TasksCreate = () => {

  //meta title
  document.title = " Offer Create  | The Wawy - Client Dashboard ";



  const notify = useSelector(state => state.NotifyReducer.action);
  const [toastStatus, setToastStatus] = useState(false)



  useEffect(() => {

    if (notify) {

      const shouldSucceed = notify.payload.status; // veya false

      const simulateFetchData = (shouldSucceed) => {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            if (shouldSucceed) {
              resolve("Data successfully fetched");
            } else {
              reject(new Error("Error when fetching data"));
            }
          }, 1000); // 2 saniye sonra resolve veya reject
        });
      };

      toast.promise(simulateFetchData(shouldSucceed), {
        loading: 'Loading',
        success: (data) => {
       if(toastStatus){ 

        setTimeout(() => {
          setToastStatus(false);
          //navigate('/MyOffers'); // navigate to new page 
          window.location.href = "/MyOffers";
        }, 2000);
       }

          setTimeout(() => {
            setToastStatus(false);
          }, 3000); // 2 saniye sonra setToastStatus(false) çalışır


          // Başlık ve mesajı kullanarak bir string oluşturabilir veya başka bir şekilde işleyebilirsiniz.
          return (
            <>
              <div>
                <strong>{notify.payload.title}</strong> <br />{notify.payload.message}
              </div>
            </>
          );
        },
        error: (data) => {
          // Başlık ve mesajı kullanarak toastStatus string oluşturabilir veya başka bir şekilde işleyebilirsiniz.
          return (
            <>
              <div>
                <strong>{notify.payload.title}</strong> <br />{notify.payload.message}
              </div>
            </>
          );
        },
      }, {
        success: {
          duration: 3000,

        },

      });


    }
  }, [notify]);





  const dispatch = useDispatch()

  // ****************WORK TYPE İŞLEMLERİ BAŞLAR********************* 
  const [worktypeName, seTworktypeName] = useState()
  const [worktype, setWorktype] = useState([
    { id: 1, name: "Nurse", images: imgnurse, checked: false },
    { id: 2, name: "UNR Nurse", images: imgunnurse, checked: false },
    { id: 3, name: "Healthcare", images: imghealthcare, checked: false },
    { id: 4, name: "Nannies", images: imgnanny, checked: true },
    { id: 5, name: "Babysitter", images: imgbabysitter, checked: false },
    { id: 6, name: "Housekeeping", images: imghousekeeping, checked: false }
  ]);

  const handleSetWorkType = (id) => {
    const updatedWorktype = worktype.map(item => {
      if (item.id === id) {
        seTworktypeName(item.name);
        return { ...item, checked: true };
      } else {
        return { ...item, checked: false };
      }
    });
    setWorktype(updatedWorktype);
  };


  // ****************WORK TIME İŞLEMLERİ BAŞLAR********************* 
  const [worktimeName, seTworktimeName] = useState()
  const [worktime, setWorktime] = useState([
    { id: 1, name: "Daily", images: daily, checked: false },
    { id: 2, name: "Weakly", images: weakly, checked: false },
    { id: 3, name: "Monthly", images: monthly, checked: false },
    { id: 4, name: "Live in", images: livein, checked: false }
  ]);

  const handleSetWorkTime = (id) => {
    const updatedWorktime = worktime.map(item => {
      if (item.id === id) {
        seTworktimeName(item.name)
        return { ...item, checked: true };
      } else {
        return { ...item, checked: false };
      }
    });
    setWorktime(updatedWorktime);
  };



  // ****************HOURS İŞLEMLERİ BAŞLAR********************* 
  const [hoursName, sethoursName] = useState()
  const [hours, setHours] = useState([
    { id: 1, name: "1 Hour", images: time3, checked: false },
    { id: 2, name: "2 Hour", images: time3, checked: false },
    { id: 3, name: "3 Hour", images: time3, checked: false },
    { id: 4, name: "4 Hour", images: time3, checked: false },
    { id: 5, name: "5 Hour", images: time3, checked: false },
    { id: 6, name: "6 Hour", images: time3, checked: false },
    { id: 7, name: "7 Hour", images: time3, checked: false },
    { id: 8, name: "8 Hour", images: time3, checked: false },
    { id: 9, name: "9 Hour", images: time3, checked: false },
    { id: 10, name: "10 Hour", images: time3, checked: false },
    { id: 11, name: "12 Hour", images: time3, checked: false },
    { id: 12, name: "7/24", images: time4, checked: false }
  ])

  const handleSetHour = (id) => {
    const updatedHours = hours.map(item => {
      if (item.id === id) {
        sethoursName(item.name)
        return { ...item, checked: true };
      } else {
        return { ...item, checked: false };
      }
    });
    setHours(updatedHours);
  };




  // ****************DAYS İŞLEMLERİ BAŞLAR********************* 
  const [days, setDays] = useState([
    { id: 1, name: "Monday", images: allday, checked: false },
    { id: 2, name: "Tuesday", images: allday, checked: false },
    { id: 3, name: "Wednesday", images: allday, checked: false },
    { id: 4, name: "Thursday", images: allday, checked: false },
    { id: 5, name: "Friday", images: allday, checked: false },
    { id: 6, name: "Saturday", images: allday, checked: false },
    { id: 7, name: "Sunday", images: allday, checked: false }
  ])

  const handleSetDay = id => {
    const updatedDays = days.map(item => {
      if (item.id === id) {
        return { ...item, checked: !item.checked };
      }
      return item;
    });
    setDays(updatedDays);
  };



  // ****************DENEYİM İŞLEMLERİ BAŞLAR********************* 
  const [futureName, setFutureName] = useState("");
  const [future, setFuture] = useState([]);

  // ÖZel deneyim ekler  
  const handleAddFuture = () => {
    const maxId = future.reduce((max, obj) => (obj.id > max ? obj.id : max), 0);
    const num = maxId + 1;
    const data = future.some(obj => obj.name === futureName) ? [...future] : [...future, { id: num, name: futureName }];

    setFuture(data)
    setFutureName(''); // input elementinin değerini sıfırla
  }

  // Eklenen özel deneyimi siler
  const handleRemoveFuture = (id) => {
    const updatedFuture = future.filter(obj => obj.id !== id);
    setFuture(updatedFuture);

  }



  // ****************RISK İŞLEMLERİ BAŞLAR********************* 

  const [risk, setRisk] = useState([
    { id: 1, name: "Low", images: imglow, checked: false },
    { id: 2, name: "Medium", images: imgmedium, checked: false },
    { id: 3, name: "High", images: imghigh, checked: false }
  ]);

  const handleSeRisk = (id) => {
    const updatedRisk = risk.map(item => {
      if (item.id === id) {
        return { ...item, checked: true };
      } else {
        return { ...item, checked: false };
      }
    });
    setRisk(updatedRisk);
  };




  // ****************EDITOR İÇERİK İŞLEMLERİ BAŞLAR********************* 
  // Editörden gelen ham veriyi editorState atıyoruz.
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const handleContentChange = (state) => {
    setEditorState(state);
  };

  // buradaki işlemleri  handleContentChange  içerisinde yapamıyoruz  editör içerisindeki style özelliklerini tam alamıyoruz. Bu yüzden editorState degişiklik oldugunda veriyi alıp 
  // html kodlaı ile birlikte string olarak   "lastdescription"    set ediyoruz. daah sonra ekrana html kodları ile bastırmak için <div dangerouslySetInnerHTML={{ __html: lastdescription }}></div> kullanacagız.
  const [lastdescription, setlastdescription] = useState();
  useEffect(() => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    setlastdescription(draftToHtml(rawContentState))
  }, [editorState])




  const [offername, setoffername] = useState("");
  const [price, setprice] = useState("");


  var total = days.filter(day => day.checked === true).length;




  const handleSubmit = () => {
    setToastStatus(true)
    var data = {
      offername: offername,
      offerdescription: lastdescription,
      price: price,
      worktypeName: worktypeName,
      worktimeName: worktimeName,
      hoursName: hoursName,
      worktypeArray: worktype,
      worktimeArray: worktime,
      hoursArray: hours,
      futureArray: future,
      daysArray: days,
      risk: risk,
      days: total

    }

    dispatch(Offer_Create_GET(data))
  }



  return (
    <>
      <div className="page-content">

        <Container fluid>
          {toastStatus ? <Toaster position="top-right" reverseOrder={false} /> : <></>}
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={"Offer"} breadcrumbItem={"Create Offer"} />

          <Row>
            <Col lg="12">
              <Card>


                <div>
                </div>

                <CardBody>

                  <CardTitle className="mb-4">{" "}</CardTitle>
                  <form className="outer-repeater">
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="taskname"
                            className="col-form-label col-lg-2"
                          >
                            Offer Name
                          </Label>
                          <Col lg="10">
                            <Input
                              id="taskname"
                              name="taskname"
                              type="text"
                              className="form-control"
                              placeholder="Enter Offer Name..."
                              onChange={(e) => setoffername(e.target.value)}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-2">
                            Offer Description
                          </Label>
                          <Col lg="10">
                            <Editor
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              placeholder="Place Your Content Here..."
                              editorState={editorState}
                              onEditorStateChange={handleContentChange}  // kendi props özelligi state diye bir parametre otomatik gönderir.
                            />
                          </Col>
                        </FormGroup>





                        <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2"
                          >
                            Price (hour)
                          </label>
                          <div className="col-lg-10">
                            <InputGroup>
                              <div className="input-group-text">£</div>
                              <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="0" onChange={(e) => setprice(e.target.value)} />
                            </InputGroup>
                          </div>
                        </FormGroup>


                        <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2 "
                          >
                            {" "}
                          </label>
                          <div className="col-lg-10">

                            <Row >


                              <Col sm="12" className="ml-0  border-0 via-black mb-1 mt-4 " >
                                <Col sm="12" className=" text-sm font-medium col-form-label border-bottom border-2 border-b-sky-600  bg-rose-500  pl-0 ml-1 mb-4"><span className=" bg-rose-500  text-cyan-50 pl-2 pr-2 pt-2 pb-[9px]">WORKER TYPE </span></Col>
                                <Row>
                                  {worktype.map((item, key) => (
                                    <Col key={key} xl="2" sm="4">
                                      <div className="mb-3">
                                        <label className="card-radio-label mb-2">
                                          <input
                                            type="radio"
                                            name={item.name}
                                            id="buycurrencyoption1"
                                            className="card-radio-input "
                                            onChange={(e) => { handleSetWorkType(item.id) }}
                                            // defaultChecked
                                            checked={item.checked}
                                            readOnly
                                          />

                                          <div className="card-radio card-radio-worker">
                                            <div className="p-2">
                                              <img src={item.images} className={`w-[36px] text-warning align-middle  ${item.name == "Housekeeping" ? 'me-1 -ml-4' : 'me-2'}`} />

                                              {" "}
                                              <span>{item.name}</span>
                                            </div>
                                          </div>
                                        </label>


                                      </div>
                                    </Col>

                                  ))}

                                </Row>
                              </Col>

                              <Col sm="12" className="ml-0  border-0 via-black mb-4 mt-4 " >
                                <div className="">

                                  <Col sm="12" className=" text-sm font-medium col-form-label border-bottom border-2 border-b-sky-600  bg-lime-500  pl-0 ml-1 mb-4"><span className=" bg-lime-500 text-cyan-50 pl-2 pr-2 pt-2 pb-[9px]">WORK TIME</span></Col>

                                  <Row>


                                    {worktime.map((item, key) => (
                                      <Col key={key} xl="2" sm="4">
                                        <div className="mb-3">
                                          <label className="card-radio-label mb-2">
                                            <input
                                              type="radio"
                                              name={item.name}
                                              id="buycurrencyoption1"
                                              className="card-radio-input "
                                              onChange={(e) => { handleSetWorkTime(item.id) }}
                                              checked={item.checked}
                                              readOnly
                                            />

                                            <div className="card-radio card-radio-week">
                                              <div className="p-2">
                                                <img src={item.images} className=" w-[24px] text-warning align-middle me-2" />
                                                {" "}
                                                <span>{item.name}</span>
                                              </div>
                                            </div>
                                          </label>


                                        </div>
                                      </Col>
                                    ))}




                                  </Row>
                                </div>
                              </Col>


                              <Col sm="12" className="ml-0  border-0 via-black  mb-4  " >


                                <Col sm="12" className=" text-sm font-medium col-form-label border-bottom border-2 border-b-sky-600 bg-amber-300  pl-0 ml-1 mb-4"><span className=" bg-amber-300 text-cyan-50 pl-2 pr-2 pt-2 pb-[9px]">WORK HOUR</span></Col>

                                <Row>
                                  {hours.map((item, key) => (

                                    <Col key={key} xl="2" sm="4">
                                      <div className="mb-3 text-center ">
                                        <label className="card-radio-label mb-2">
                                          <input
                                            type="radio"
                                            name={item.name}
                                            id="buycurrencyoption1"
                                            className="card-radio-input"
                                            onChange={(e) => { handleSetHour(item.id) }}
                                            checked={item.checked}
                                            readOnly
                                          />

                                          <div className="card-radio card-radio-time">
                                            <div className="">
                                              <img src={item.images} className=" w-[24px] text-warning align-middle me-2" />
                                              {" "}
                                              <span className="">{item.name}</span>
                                            </div>
                                          </div>
                                        </label>
                                      </div>
                                    </Col>
                                  ))}
                                </Row>

                              </Col>



                              <Col sm="12" className="ml-0  border-0 via-black  " >


                                <Col sm="12" className=" text-sm font-medium col-form-label border-bottom border-2 border-b-sky-600  bg-sky-400 pl-0 ml-1 mb-4"><span className=" bg-sky-400 text-cyan-50 pl-2 pr-2 pt-2 pb-[9px]">WORK DAY</span></Col>
                                <Row>
                                  {days.map((item, key) => (
                                    <Col key={key} xl="2" sm="4" >
                                      <div className="mb-3 text-center ">
                                        <label className="card-radio-label mb-2 ">
                                          <input
                                            type="checkbox"
                                            name={item.name}
                                            className="card-radio-input"
                                            onChange={() => handleSetDay(item.id)}

                                          />

                                          <div className="card-radio card-radio-my">
                                            <div className="">
                                              <img src={item.images} className=" w-[24px] text-warning align-middle me-2" />
                                              {" "}
                                              <span className=""> {item.name} </span>
                                            </div>
                                          </div>
                                        </label>


                                      </div>
                                    </Col>
                                  ))}
                                </Row>

                              </Col>


                              <Col sm="12" className="ml-0  border-0 via-black  " >


                                <Col sm="12" className=" text-sm font-medium col-form-label border-bottom border-2 border-b-teal-500  bg-teal-500 pl-0 ml-1 mb-4"><span className=" bg-teal-500 text-cyan-50 pl-2 pr-2 pt-2 pb-[9px]">PRIORITY LEVEL</span></Col>
                                <Row>
                                  {risk.map((item, key) => (
                                    <Col key={key} xl="2" sm="4" >
                                      <div className="mb-3 text-center ">
                                        <label className="card-radio-label mb-2 ">
                                          <input
                                            type="checkbox"
                                            name={item.name}
                                            id="buycurrencyoption1"
                                            className="card-radio-input"
                                            onChange={() => handleSeRisk(item.id)}
                                            checked={item.checked}
                                            readOnly

                                          />

                                          <div className="card-radio card-radio-risk">
                                            <div className="">
                                              <img src={item.images} className=" w-[52px] text-warning align-middle me-2" />
                                              {" "}
                                              <span className=""> {item.name} </span>
                                            </div>
                                          </div>
                                        </label>


                                      </div>
                                    </Col>
                                  ))}
                                </Row>

                              </Col>



                              <Col sm="12" className="ml-0  border-0 via-black  mt-4 " >


                                <Col sm="12" className=" text-sm font-medium col-form-label border-bottom border-2 border-b-sky-600  bg-red-500 pl-0 ml-1 mb-4"><span className=" bg-red-500 text-cyan-50 pl-2 pr-2 pt-2 pb-[9px]">EXPERIENCES</span></Col>
                                <Row>
                                  <Col md="12" className="mt-2 mb-4">
                                    <span className=" font-bold">
                                      You can include the specific experiences and skills you require from applicants who will apply to this job offer.
                                    </span>
                                  </Col>

                                  <div className="mb-3 row align-items-center" >
                                    <Col md="6">
                                      <input
                                        type="text"
                                        name="future"
                                        className="inner form-control"
                                        placeholder="Enter Experience..."
                                        value={futureName}
                                        onChange={(e) => setFutureName(e.target.value)}
                                      />
                                    </Col>

                                    <Col md="2">
                                      <div className="mt-2 mt-md-0 d-grid">
                                        <Button
                                          color="success"
                                          className="inner"
                                          onClick={() => { handleAddFuture() }}
                                          block
                                        >
                                          Add Experience
                                        </Button>
                                      </div>
                                    </Col>

                                    <Col md="12" className="mt-4" >
                                      <Row>
                                        {(future || []).map((item, key) => (
                                          <Col md="3" key={key} className="mt-2" > <span className="px-3 py-2 rounded bg-light bg-opacity-50 d-block mb-2">{item.name}<span onClick={() => handleRemoveFuture(item.id)} className="badge text-bg-danger float-end bg-opacity-100"><i className="bx bx-trash text-xs"></i></span></span> </Col>
                                        ))}


                                      </Row>

                                    </Col>
                                  </div>
                                </Row>
                              </Col>

                            </Row>


                          </div>





                        </FormGroup>
                      </div>
                    </div>

                  </form>

                  <Row className="justify-content-end">
                    <Col lg="10" className=" text-center" >
                      {toastStatus ? <>           
                    <button className="w-full h-[80px] px-6 text-indigo-100 border rounded-md btn btn-outline-light mb-6">     <Dna
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="dna-loading"
                        wrapperStyle={{}}
                        wrapperClass="dna-wrapper"
                      /></button>

                      </> :
                        <button onClick={() => handleSubmit()} className="w-full h-12 px-6 text-indigo-100 border rounded-md  bg-indigo-500 hover:bg-indigo-600 mb-6">Publish Offer Now</button>
                      }
                    </Col>

                  </Row>

                  <Row className="justify-content-end">
                    <Col lg="10">

                    </Col>

                  </Row>
                </CardBody>

              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default TasksCreate





/*

                       <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2"
                          >
                            Budget
                          </label>
                          <div className="col-lg-10">
                            <Input
                              id="taskbudget"
                              name="taskbudget"
                              type="text"
                              placeholder="Enter Task Budget..."
                              className="form-control"
                            />
                          </div>
                        </FormGroup>

*/





/*

const [editorState, setEditorState] = useState( () => EditorState.createEmpty());
const [convertedContent, setConvertedContent] = useState(null);


useEffect(() => {
  let html = convertToHTML(editorState.getCurrentContent());
  setConvertedContent(html);
}, [editorState]);


     <div dangerouslySetInnerHTML={createMarkup(convertedContent)}></div>



*/