import React, { useEffect, useState } from "react";
// import { Link, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { isEmpty, map } from "lodash";
import * as moment from "moment";
import {
  Badge,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import ParticleEffectButton from 'react-particle-effect-button'
import avatar4 from "../../assets/images/users/avatar-4.jpg";
//redux
import { useSelector, useDispatch } from "react-redux";

import { Jobs_friend_GET, Jobs_friend_check_GET } from "store/actions";

const ProjectStatus = ({ status }) => {
  switch (status) {
    case "Pending":
      return <Badge className="bg-warning"> {status} </Badge>;

    case "Delay":
      return <Badge className="bg-danger"> {status} </Badge>;

    case "Completed":
      return <Badge className="bg-success"> {status} </Badge>;

    default:
      return <Badge className="bg-success"> {status} </Badge>;
  }
};

const FriendsModal = (props) => {


  //meta title
  document.title = "Friend Modal | The Wawy - Client Dashboard ";

  const dispatch = useDispatch();
  
  const  [job, setJob] = useState(props.job)
  const  [jobCheck, setjobCheck] = useState(null)
  const handleFriendSend = (job,friend_id) => {
    dispatch(Jobs_friend_GET(job,friend_id))
    setTimeout(() => {
      dispatch(Jobs_friend_check_GET(job._id))
    }, 3200);
 setEffect(true)
    if(jobCheck)  {setjobCheck(false)} else {setjobCheck(true)}
  }

  const [effect, setEffect] = useState(false);
  const [activekey, setactivekey] = useState(null);
  
  const [backgroundStyle, setBackgroundStyle] = useState('btn btn-info');
  const [color, setcolor] = useState('#3498db');

  useEffect(() => {
    if (effect) {
      const timer = setTimeout(() => {
        setEffect(false);
        setBackgroundStyle('btn btn-secondary'); // Geriye doğru başa sarıldığında arka plan rengini mavi olarak ayarla
        setcolor('#636e72'); // Geriye doğru başa sarıldığında arka plan rengini mavi olarak ayarla
      }, 1850);
      
      return () => {
        clearTimeout(timer);
      };
    }
  }, [effect]);

  const handleButtonClick = () => {
   
    setEffect(true);
    setBackgroundStyle('btn btn-secondary');
  };

  const handleEffect = (status,key) => {
    setactivekey(key)
    setEffect(status)

  }




  const [newJob, setNewjob] = useState()
  

 
  useEffect(() => {
    dispatch(Jobs_friend_check_GET(job._id))
  },[job])




const { friends,newJ} = useSelector((state) => ({
    friends:state.Dashboard.action.datam,
    newJ:state.JobsFriendCheckdReducers.action
}))

var friendsMap =[];
if(friends) {var friendsMap =friends.friends;}
if(newJ) {  var newX =newJ.FriendCheckData.data.jobs  }

useEffect(()=> {
  setNewjob(newX)
 
},[newJ,job,friends])




  return (
    <React.Fragment>



    {newJob ?  
    
    <Row>
 
    <Col  className="ml-2 mr-2 w-[100%] ">
  
        <div className="">
          <Table className="project-list-table table-nowrap align-middle table-borderless">
            <thead>
              <tr>
              
              </tr>
            </thead>
            <tbody>
              {friendsMap.map((friend,key) => (
                                        <tr key={key}>
                                        <td>
                                          <img
                                            src={avatar4}
                                            alt=""
                                            className="avatar-sm rounded "
                                          />
                                        </td>
                                        <td>
                                          <h5 className="text-truncate font-size-14">
                                            <Link
                                              to={`/projects-overview/`}
                                              className="text-dark"
                                            >
                                             {friend.friend_name}
                                            </Link>
                                          </h5>
                                          <p className="text-muted mb-0">
                                          {friend.email}
                                          </p>
                                        </td>
                                        <td> 22 Oct 2019</td>
                                        <td>
                                            {map(friend.services,(service,key) => ( 
                                    
                                           <Row   key={key} >
                                            <Col lg={6}><Badge className="bg-warning">{service}</Badge></Col>
                                           </Row>
                                               
                                            ))}
                                        
                                        </td>
                                    
                                        <td>
                                          {
                                           
                                          newJob.shares.find((id) => id.partner_id === friend.friend_id) ? 
                                          
                                          <div className=""><button  className="btn btn-secondary btn-sm pl-2 pr-2  mr-1 text-xs   justify-center flex"> <i className="bx bx-mail-send   text-xs mr-1"> {" "} </i> Send</button></div>
                                          :
                                          
                                          
                                          
                                          <ParticleEffectButton 
                                          duration={700} 
                                          fadeOutDuration={700}
                                          color={color}
                                          hidden={activekey==key ? effect : false}
                                          type="triangle"
                                          onAnimationEnd={() => setEffect(false)}
                                        >
                                          <button onClick={() => {handleEffect(true,key), handleFriendSend(job,friend.friend_id)}}  className={activekey === key ?  backgroundStyle+" overflow-auto mt-[6px] btn-sm pl-2 pr-2 mr-1 text-xs justify-center flex" : activekey!==key ? "btn btn-info overflow-auto btn btn-info mt-[6px] btn-sm pl-2 pr-2 mr-1 text-xs justify-center flex" : ""   } > <i className="bx bx-mail-send  text-xs mr-1"> {" "} </i> Send</button>
                                        </ParticleEffectButton>
                                          
                                          }
                                        
              
                                        </td>
                                      </tr>
              ))}


           



            </tbody>
          </Table>
       
        </div>
    
    </Col>
    
  </Row>
    
    : <></>}


          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-secondary">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                  Load more
                </Link>
              </div>
            </Col>
          </Row>
     
    </React.Fragment>
  );
};

export default withRouter(FriendsModal);
