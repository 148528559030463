import {
  PARTNER_PROFIL_SET, 
 } from "./actionTypes";


const initialState = {
    error:"",
    status: false,
}


const ProfilReducers= (state = initialState, action) =>  {


    switch (action.type) {
     
        case PARTNER_PROFIL_SET:
          return {
            ...state,
            action
          };
      
   
        default:
          return { ...state };
      }

}


export default ProfilReducers
