import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";
import { map } from "lodash";
import images from "assets/images";







class Comments extends Component {
  constructor(props) {
    super(props);
    this.state =    {
        id: 0,
        img: "img1",
        name: "New admin Design",
        description: "It will be as simple as Occidental",
        status: "Completed",
        color: "success",
        dueDate: "15 Oct, 19",
        commentsCount: 214,
        team: [
          {
            id: 1, img: "avatar4", fullname: "Janice Cole", skills: [
              { id: 1, name: "Frontend" },
              { id: 2, name: "UI" },
            ],
          },
          {
            id: 2, img: "avatar5", fullname: "Steve Foster",
            skills: [{ id: 1, name: "UI/UX" }],
          },
          {
            id: 3, img: "Null", name: "A", color: "success", fullname: "Aeffrey Walker",
            skills: [{ id: 1, name: "Backend" }],
          },
          {
            id: 4, img: "avatar2", fullname: "Daniel Candles",
            skills: [
              { id: 1, name: "Frontend" },
              { id: 2, name: "UI" },
            ],
          },
        ],
        startDate: "08 Sept, 2019",
        projectDetails: {
          description:
            "To an English person, it will seem like simplified English, as a skeptical Cambridge friend of mine told me what Occidental is. The European languages are members of the same family. Their separate existence is a myth. For science, music, sport, etc,",
          points: [
            "To achieve this, it would be necessary",
            "Separate existence is a myth.",
            "If several languages coalesce",
          ],
        },
        files: [
          { name: "Skote Landing.Zip", size: "3.25 MB", link: "#" },
          { name: "Skote Admin.Zip", size: "3.15 MB", link: "#" },
          { name: "Skote Logo.Zip", size: "2.02 MB", link: "#" },
          { name: "Veltrix admin.Zip", size: "2.25 MB", link: "#" },
        ],
        comments: [
          {
            id: 1,
            username: "David Lambert",
            userImg: "avatar2",
            comment: "Separate existence is a myth.",
          },
          {
            id: 2,
            username: "Steve Foster",
            userImg: "avatar3",
            comment: "@Henry To an English person it will like simplified",
            reply: {
              username: "Jeffrey Walker",
              comment: "as a skeptical Cambridge friend",
            },
          },
          {
            id: 3,
            username: "Steven Carlson",
            comment: " Separate existence is a myth.",
          },
        ],
      };
  }



 




  render() {
    return (
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Last Comments</CardTitle>
          {map(this.props.info.comments, (comment, index) => (
            <div className="d-flex mb-4" key={index}>
              <div className="me-3">
                
                  <img
                    className="media-object rounded-circle avatar-xs"
                    alt=""
                    src={"./images/users/avatar-3.jpg"}
                  />
                
              </div>
              <div className="flex-grow-1">
                <h5 className="font-size-13 mb-1">{comment.client_name}</h5>
                <p className="text-muted mb-1">{comment.comment}</p>
        
              </div>
     
            </div>
          ))}
          <div className="text-center mt-4 pt-2">
            <Link to="/Comments" className="btn btn-primary btn-sm">
              View more
            </Link>
          </div>
        </CardBody>
      </Card>
    );
  }
}

Comments.propTypes = {
  comments: PropTypes.array,
};

export default Comments;
