import { 
  SCHEDULE_GET,
  SCHEDULE_SET 
      } from "./actionTypes"


export const Schedule_SET = (schedule_id,schedule) => ({
  type:SCHEDULE_SET,
  schedule_id,
  schedule
})




export const Schedule_GET = Schedule => ({
  type:SCHEDULE_GET,
  Schedule,
})