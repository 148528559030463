import { 
  SCHEDULE_GET
        } from "./actionTypes"


const initialState = {
    error:"",
    status: false,
}




 const Schedule = (state = initialState,action) => {

  switch (action.type) {

        case SCHEDULE_GET:
          return {
            ...state,
            action
          };
   
      default:
        return { ...state };
  }


}


export default Schedule        