import {
    PARTNER_PROFIL_GET, 
   
    PARTNER_PROFIL_SET, 
    } from "./actionTypes";



export const Partner_Profil_GET =  (partner_id) => ({
    type:PARTNER_PROFIL_GET,
    partner_id
})

    
export const Partner_Profil_SET =  (data) => ({
   type:PARTNER_PROFIL_SET,
   data
})


