import { 

  JOBS_BOOKMARKS_GET,
  JOBS_BOOKMARKS_SET 
      } from "./actionTypes"




export const Jobs_bookmarks_GET = (Bookmark,status) => ({
  type:JOBS_BOOKMARKS_GET,
  BookmarkData : {Bookmark,status},
})

export const Jobs_bookmarks_SET = BookmarkData => ({
  type:JOBS_BOOKMARKS_SET,
  BookmarkData,
})