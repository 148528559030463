import { 
    OFFER_CREATE_SET,
    OFFER_SET,
    OFFER_SINGLE_SET,
    OFFER_UPTADE_SET,
    OFFER_DELETE,
    OFFER_PARTNER_ACCEPT,
    OFFER_TEAM_LEADER,
    OFFER_TEAM_LEADER_SET,
    OFFER_TEAM_LEADER_GET
        } from "./actionTypes"


const initialState = {
    error:"",
    status: false,
}

const NotiInitialState = {
  status:"",
  title:"",
  message:"",
  type:"",
  action:"",
  default:""
}


const initialState2 = {
  error:"",
  status: false,
}


export const OfferReducers = (state = initialState,action) => {

    switch (action.type) {
        case OFFER_CREATE_SET:
          return {
            ...state,
            action
          };
        case OFFER_SET:
          return {
            ...state,
            action
          };
          case OFFER_SINGLE_SET:
            return {
              ...state,
              action
            };
            case OFFER_UPTADE_SET:
            return {
              ...state,
              action
            };
            case OFFER_DELETE:
              return {
                ...state,
                action
              };
           
     
        default:
          return { ...state };
    }


}



export const OfferAcceptReducers = (state = initialState,action) => {

  switch (action.type) {
    
            case OFFER_PARTNER_ACCEPT:
              return {
                ...state,
                action
              };
   
      default:
        return { ...state };
  }


}


export const OfferTeamLeaderReducers = (state = initialState,action) => {

  switch (action.type) {
    
            case OFFER_TEAM_LEADER:
              return {
                ...state,
                action
              };
   
      default:
        return { ...state };
  }


}
        









