import React, {  useState } from "react";
import { Link } from "react-router-dom";
import {Tooltip} from "reactstrap";
import { PROFIL_IMG } from "ApiConfig";


const AvatarModule = ({avatar}) => {

    const [tooltipOpen, setTooltipOpen] = useState(new Array(0).fill(false));

    // Create a toggle function that takes an index
    const toggle = (i) => {
        const newArray = [...tooltipOpen];
        newArray[i] = !newArray[i];
        setTooltipOpen(newArray);
    };




    function getFull(avatar) {
        let fullNames = [];
        if (avatar && avatar[0].applications && avatar[0].applications.length > 0) {
            const applications = avatar[0].applications;

            applications.forEach(application => {
                if (application.partner && application.partner.fullName && application.partner.profilimage) {
                    fullNames.push({ fullName: application.partner.fullName, image: application.partner.profilimage });
                }
            });
        }

        return (
            <div>
                <div className="avatar-group">
                    {fullNames.map((team, key) =>
                        !team.image || team.image !== "Null" ? (
                            <React.Fragment key={key}>

                                <div className="avatar-group-item">
                                    <Link to="#" className="d-inline-block" id={'Tooltip-' + key}
                                        onMouseEnter={() => toggle(key)}
                                        onMouseLeave={() => toggle(key)}
                                    >
                                        <img src={`${PROFIL_IMG}${team.image.picture_path}`} className="rounded-circle avatar-xs" alt="" />

                                        <Tooltip
                                            placement="top"
                                            target={'Tooltip-' + key}
                                            isOpen={tooltipOpen[key]}
                                        >
                                            {team.fullName}
                                        </Tooltip>
                                    </Link>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment key={key}>
                                <div className="avatar-group-item">
                                    <Link to="#" className="d-inline-block" id={'Tooltip-' + key}
                                        onMouseEnter={() => toggle(key)}
                                        onMouseLeave={() => toggle(key)}
                                    >
                                        <div className="avatar-xs">
                                            <span className={`avatar-title rounded-circle bg-success} text-white font-size-16`} >{"A"}  </span>

                                            <Tooltip
                                                placement="top"
                                                target={'Tooltip-' + key}
                                                isOpen={tooltipOpen[key]}
                                            >
                                                {team.fullName}
                                            </Tooltip>
                                        </div>
                                    </Link>
                                </div>
                            </React.Fragment>
                        )
                    )}





                </div>
            </div>
        );
    }






    return ( <>{getFull(avatar)}</>);


};

export default AvatarModule;


