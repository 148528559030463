import {
    PROFIL_GET, 
    ABOUTUS_GET,
    SERVICES_GET,
    CERTIFICATES_GET,
    PASSWORD_GET,
    PROFIL_SET, 
    ABOUTUS_SET,
    SERVICES_SET,
    CERTIFICATES_SET,
    PASSWORD_SET } from "./actionTypes";



export const Profil_GET =  data => ({
    type:PROFIL_GET,
    data
})

export const Aboutus_GET = data => ({
    type:ABOUTUS_GET,
    data
})

export const Services_GET = (x,data,name) => ({
    type:SERVICES_GET,
    data:{x,data,name}
})

export const Certificates_GET = (status,id) => ({
    type:CERTIFICATES_GET,
    data:{status,id}
})

export const Password_GET = data => ({
    type:PASSWORD_GET,
    data
})
    
export const Profil_SET =  (data) => ({
   type:PROFIL_SET,
    data
})

export const Aboutus_SET = data => ({
    type:ABOUTUS_SET,
    data
})

export const Services_SET = data => ({
    type:SERVICES_SET,
    data
})

export const Certificates_SET = data => ({
    type:CERTIFICATES_SET,
    data
})

export const Password_SET = password => ({
    type:PASSWORD_SET,
    password
})