export const OFFER_CREATE_GET = "OFFER_CREATE_GET"
export const OFFER_CREATE_SET = "OFFER_CREATE_SET"
export const OFFER_GET              = "OFFER_GET"
export const OFFER_SET              = "OFFER_SET"
export const OFFER_SINGLE_GET              = "OFFER_SINGLE_GET"
export const OFFER_SINGLE_SET              = "OFFER_SINGLE_SET"
export const OFFER_UPTADE_GET              = "OFFER_UPTADE_GET"
export const OFFER_UPTADE_SET              = "OFFER_UPTADE_SET"
export const OFFER_DELETE              = "OFFER_DELETE"
export const OFFER_PARTNER_ACCEPT              = "OFFER_PARTNER_ACCEPT"
export const OFFER_TEAM_LEADER             = "OFFER_TEAM_LEADER"

